import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfile } from '../../../data/user.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent  implements OnInit {

  users?: Observable<UserProfile[]>

  @Input() communityId?: string;
  
  constructor(){}

  ngOnInit()
  {
    //To implement after users are moved to mongodb
  }

}
