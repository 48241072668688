import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonContent, Platform } from '@ionic/angular';
import { CommunityService } from '../../../data/community.service';
import { CommunityInfos, TileData } from 'types/build';
import { first, Observable, Subscription } from 'rxjs';
import { MapService } from 'app/src/app/data/map.service';
import { Colors } from 'app/src/app/utils/Colors';

@Component({
  selector: 'app-community-overlay',
  templateUrl: './community-overlay.component.html',
  styleUrls: ['./community-overlay.component.scss'],
})
export class CommunityOverlayComponent implements OnInit, AfterViewInit, OnDestroy {

  modalBreakpoints = [0, 0.5, 1];
  communityId?: string;
  communityInfos?: Observable<CommunityInfos>;
  isMobile?: boolean;

  mapDataSubscription?: Subscription;
  
  @ViewChild("mainContainer") content!: ElementRef<HTMLElement>;

  constructor(private route: ActivatedRoute,
    private platform: Platform,
    private mapService: MapService,
    private communityService: CommunityService
  ) { }

  ngOnInit() {
    if (!this.route.snapshot.paramMap.get('communityId')) return;

    this.communityId = this.route.snapshot.paramMap.get('communityId')!;
    this.isMobile = this.platform.is('mobile');
    this.communityInfos = this.communityService.getCommunityInfo(this.communityId)
    this.mapDataSubscription = this.mapService.mapData?.subscribe((md) => {
      if (md && this.communityId) {
        this.mapService.focusCommunity(this.communityId);
      }
    });
  }

  ngAfterViewInit(): void {
    this.communityInfos?.pipe(first()).subscribe((c) => {
      let color = Colors.shadeHexColor(c.color, -0.8);
      this.content.nativeElement.style.setProperty('background', `linear-gradient(180deg, ${color}a3 0%, var(--ion-background-color) 100%)`);
    });
  }

  ngOnDestroy(): void {
    this.mapDataSubscription?.unsubscribe();
  }
}
