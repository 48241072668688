import { WorldPoint } from "./MapData"

/**
 * Database content schema
 */
export interface ICommunitySchema
{
    _id: string,
    _name: string,
    _color: string,
    _description: string,
    _image: string | undefined,
    _nbUsers: number,
    _nbBookmarks: number,
    _tileNumber: number,
    _creatorId: string,
    _tags: string[],
    _bans: string[],
    _moderators: string[],
    _admins: string[],
    _firstTilePosition: WorldPoint,
    _creationDate: number,
    _sponsored: boolean
}
export interface CommunityCreatePOST {
    name: string
    color: string
    tags: string[]
    description: string,
    firstTilePosition: WorldPoint,
    image: string | undefined,
    sponsored: boolean
}
export interface CommunityCreationInfos extends CommunityCreatePOST
{
    tileNumber: number
    nbUsers: number
    nbBookmarks: number
    creatorId: string,
    moderators: string[],
    admins: string[],
    bans: string[],
}

export type CommunityInfos = Omit<CommunityCreationInfos & { id: string }, "firstTilePosition">

export class PollCreationInfos {
    _communityId: string;
    _userId: string;
    _color: string;
    _title: string;
    _choices: PollChoice[];

    constructor(communityId: string, userId: string, color: string, title: string, choices: PollChoice[])
    {
        this._communityId = communityId;
        this._userId = userId;
        this._color = color;
        this._title = title;
        this._choices = choices;
    }

    public get communityId(): string { return this._communityId; }
    public get userId(): string { return this._userId; }
    public get color(): string { return this._color; }
    public get title(): string { return this._title; }
    public get choices(): PollChoice[] { return this._choices; }

    public set communityId(value: string) { this._communityId = value; }
    public set userId(value: string) { this._userId = value; }
    public set color(value: string) { this._color = value; }
    public set title(value: string) { this._title = value; }
    public set choices(value: PollChoice[]) { this._choices = value; }
}   

export class Poll extends PollCreationInfos {
    _id: string;
    _postDate: number;
    _nbVoters: number;

    constructor(id: string, communityId: string, userId: string, color: string, title: string, choices: PollChoice[], postDate: number, nbVoters: number)
    {
        super(communityId, userId, color, title, choices);
        this._id = id;
        this._postDate = postDate;
        this._nbVoters = nbVoters;
    }

    public get id(): string { return this._id; }
    public get postDate(): number { return this._postDate; }
    public get nbVoters(): number { return this._nbVoters; }

    public set id(value: string) { this._id = value; }
    public set postDate(value: number) { this._postDate = value; }
    public set nbVoters(value: number) { this._nbVoters = value; }

    toJSON() {
        return {
            id: this._id,
            communityId: this._communityId,
            userId: this._userId,
            color: this._color,
            title: this._title,
            choices: this._choices,
            nbVoters: this._nbVoters,
            postDate: this._postDate
        }
    }

    static fromJSON(object: { [key: string]: any }): Poll {
        return new Poll(
            object['id'],
            object['communityId'],
            object['userId'],
            object['color'],
            object['title'],
            object['choices'],
            object['postDate'],
            object['nbVoters']
        );
    }
}

export type IPollSchema = {
    _id: string;
    _communityId: string;
    _userId: string;
    _color: string;
    _title: string;
    _choices: PollChoice[];
    _nbVoters: number;
    _postDate: number;
}

export class PollChoice
{
    title: string;
    score: number;
    index: 1 | 2 | 3 | 4;

    constructor(title: string, score: number, index: 1 | 2 | 3 | 4)
    {
        this.title = title;
        this.score = score;
        this.index = index;
    }
}

export class UserPollChoice extends PollChoice
{
    pollId: string;
    userId: string;

    constructor(userId: string, pollId: string, title: string, score: number, index: 1 | 2 | 3 | 4)
    {
        super(title, score, index)

        this.pollId = pollId;
        this.userId = userId;
    }
}