import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'app/src/environments/environment';

@Pipe({
  name: 'isMaxSponso'
})
export class IsMaxSponsoPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): boolean {
    return value == environment.maxSponsos;
  }

}
