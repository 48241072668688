import { AfterViewInit, Component, Injectable, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentsService } from 'app/src/app/data/contents.service';
import { InputfieldComponent } from '../../utils/inputfield/inputfield.component';
import { BehaviorSubject, Subscription, first } from 'rxjs';
import { IonPopover, Platform } from '@ionic/angular/common';

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss'],
})
export class SponsorComponent  implements OnInit, AfterViewInit {

  // if false, means that the component is used to give overbucks to content
  isSponsoring: boolean | undefined;
  contentId: string | undefined;
  initialValue: number | undefined;

  didPresentSubscription: Subscription | undefined;
  currentInvestedValueSubscription: Subscription | undefined;

  @ViewChild('sponsor') modal: IonPopover | undefined;
  @ViewChild('inputField') inputField: InputfieldComponent | undefined;

  // if true, means that the component is opened from the post modal
  @Input('isPosting') isPosting: boolean | undefined;
  @Input('trigger') trigger: string | undefined;
  @Input('contentId') contentIdInput: string | undefined;

  investedValueConfirmed: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  currentInvestedValue: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  isMobile: boolean | undefined;
  
  constructor(private Platform: Platform,
              private Route: ActivatedRoute,
              private ContentsService: ContentsService) { }

  ngOnInit()
  {
    this.Route.data.subscribe(value => this.isSponsoring = value['isSponsoring']);
    let id = this.Route.snapshot.queryParams['id'];
    if (id)
    {
      this.contentId = id;
    }

    if (this.Platform.is('mobile'))
    {
      this.isMobile = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.modal)
    {
       this.modal?.didPresent.subscribe(value => 
        {
          if (this.inputField)
          {
            this.currentInvestedValue = this.inputField.inputValueChanged;
          }
        })
    }

    if (this.Platform.is('desktop'))
    {
      document.getElementById("sponsor")?.style.setProperty('--overlie-popover-width', '25%');
    }
  }

  async Open(isSponsoring: boolean, isPosting: boolean, contentId: string, currentInvestedValue: number = 0)
  {
    this.isSponsoring = isSponsoring;
    this.isPosting = isPosting;
    this.contentId = contentId;
    await this.modal?.present();
    if (this.inputField)
    {
      this.initialValue = currentInvestedValue
      if (currentInvestedValue > 0)
      {
        this.inputField.SetValue(currentInvestedValue)
      }
    }
  }

  Confirm()
  {
    if (!this.isPosting)
    {
      this.currentInvestedValue?.pipe(first()).subscribe(value =>
        {
          if (this.contentId)
          {
            this.ContentsService.sponsorContent(this.contentId, value, this.isSponsoring ?? false);
          }
        })
    }
    else
    {
        this.investedValueConfirmed.next(this.currentInvestedValue?.getValue())
    }

    this.modal?.dismiss();
  }

  Cancel()
  {
    this.investedValueConfirmed.next(0);
    this.modal?.dismiss();
  }

  Close() {
    this.modal?.dismiss();

    if (this.initialValue)
    {
      this.investedValueConfirmed.next(this.initialValue)
    }
  }
}
