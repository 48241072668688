import { Component, OnInit } from '@angular/core';
import { CommunityService } from 'app/src/app/data/community.service';
import { InteractionsService } from 'app/src/app/data/interactions.service';
import { UserService } from 'app/src/app/data/user.service';
import { Observable, map, merge, of } from 'rxjs';

enum TileSelectionMode {
  None = 0,
  Empty = 1,
  Occupied = 2,
  Overlie = 3,
}

@Component({
  selector: 'app-map-overlay',
  templateUrl: './map-overlay.component.html',
  styleUrls: ['./map-overlay.component.scss'],
})
export class MapOverlayComponent  implements OnInit {
  tileSelectionMode = TileSelectionMode.None;
  timer$: Observable<number | null> = this.interactions.timer$;
  tileAvailable$: Observable<boolean> = this.interactions.tileAvailable$;
  waitingForSponsoredCommunity?: Observable<boolean>;
  constructor(private interactions: InteractionsService,
    private userService: UserService,
    private communityService: CommunityService ) { }

  ngOnInit() {
    this.waitingForSponsoredCommunity = merge(of(this.userService.userProfile.value.waitingForSponsoredCommunityCreation != undefined),
      this.communityService.sponsoredCommunityCreated.pipe(map(_ => false)))
  }

  putTile() {
    this.interactions.sendSignal<boolean>(this.interactions.addTileButtonClicked, true);
  }

  createCommunity()
  {
    this.interactions.sendSignal<boolean>(this.interactions.createCommunityButtonClicked, true);
  }

  createSponsoredCommunity()
  {
    this.interactions.sendSignal<boolean>(this.interactions.createSponsoredCommunityButtonClicked, true);
  }

  viewTile() {
    this.interactions.sendSignal<boolean>(this.interactions.viewTileButtonClicked, true);
  }
}
