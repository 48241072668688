import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { UserService } from '../data/user.service';

export const AuthGuard: CanActivateFn = (route, state): Observable<boolean> => {
  let userService = inject(UserService);
  let router = inject(Router);
  return userService.isAuthenticated().pipe(tap((isAuthenticated) => {
    if (!isAuthenticated) {
      router.navigate(['/signin']);
    }
  }));
};

export const UnauthGuard: CanActivateFn = (route, state): Observable<boolean> => {
  let userService = inject(UserService);
  let router = inject(Router);
  return userService.isAuthenticated().pipe(tap((isAuthenticated) => {
    if (isAuthenticated) {
      router.navigate(['/']);
    }
  }), map((isAuthenticated) => !isAuthenticated));
};
