import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SponsorComponent } from '../sponsor/sponsor.component';
import { ContentsService } from 'app/src/app/data/contents.service';

@Component({
  selector: 'app-overbucks',
  templateUrl: './overbucks.component.html',
  styleUrls: ['./overbucks.component.scss'],
})
export class OverbucksComponent  implements OnInit {

  @Input() amount: Number | undefined;
  @Input() iconSize: Number | undefined;
  @Input() fontSize: Number | undefined;
  @Input() id: String | undefined;
  @Input() contentId: String | undefined;

  @ViewChild('sponsorModal') sponsorModal: SponsorComponent | undefined;

  uniqueId: string | undefined;

  constructor(private ContentsService: ContentsService) { }

  ngOnInit() {}

  simpleLike()
  {
    if (this.id)
    {
      this.ContentsService.sponsorContent(this.id as string, 1, false)
    }
  }
}
