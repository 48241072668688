import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-inputfield',
  templateUrl: './inputfield.component.html',
  styleUrls: ['./inputfield.component.scss'],
})
export class InputfieldComponent implements OnInit, OnDestroy {

  _currentValue: number | undefined
  inputValueChanged: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  @ViewChild('input') input: IonInput | undefined

  constructor() { }

  ngOnInit()
  {
    this._currentValue = 0
  }

  ValueChanged(event: CustomEvent)
  {
    this.SetValue(+event.detail.value)
  }

  SetValue(value: number)
  {
    this._currentValue = value
    if (this._currentValue as number < 0)
    {
      this._currentValue = 0
      this.input!.value = 0
    }
    this.inputValueChanged.next(this._currentValue)
  }

  Decrease()
  {
    if (this._currentValue as number > 0)
    {
      (this._currentValue as number)--
      this.inputValueChanged.next(this._currentValue as number)
    }
  }

  Increase()
  {
    (this._currentValue as number)++
    this.inputValueChanged.next(this._currentValue as number)
  }

  ngOnDestroy(): void {
    this.inputValueChanged.next(0)
  }

}
