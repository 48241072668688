import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject, distinctUntilChanged, map, scan, startWith, switchMap, take, takeWhile, tap, timer } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class InteractionsService {
  constructor(private userService: UserService) { }

  contentBackgroundColor = new ReplaySubject<string>();
  contentBackgroundColor$ = this.contentBackgroundColor.asObservable();

  addTileButtonClicked = new Subject<boolean>();
  addTileButtonClicked$ = this.addTileButtonClicked.asObservable();

  viewTileButtonClicked = new Subject<boolean>();
  viewTileButtonClicked$ = this.viewTileButtonClicked.asObservable();

  createCommunityButtonClicked = new Subject<boolean>();
  createCommunityButtonClicked$ = this.createCommunityButtonClicked.asObservable();

  goButtonClicked = new Subject<boolean>();
  goButtonClicked$ = this.goButtonClicked.asObservable();

  timer = new BehaviorSubject<number | null>(null);
  timer$ = this.timer.asObservable();

  tileAvailable$ = this.timer$.pipe(map(value => value === 0));

  sendSignal<T>(reference: ReplaySubject<T> | BehaviorSubject<T> | Subject<T>, value: any) {
    reference.next(value);
  }

  initTimer() {
    this.userService.userProfile.pipe(
      map(userProfile => userProfile.nextTileDate),
      distinctUntilChanged(),
      switchMap(nextTileDate => timer(0, 1000).pipe(
        startWith(0),
        map(() => Math.max(nextTileDate - Date.now(), 0))
      ))
    ).subscribe(newValue => this.timer.next(newValue));
  }
}
