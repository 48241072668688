import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-simple-button',
  templateUrl: './simple-button.component.html',
  styleUrls: ['./simple-button.component.scss'],
})
export class SimpleButtonComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

  @Input() text?: string;
  @Input() disabled?: boolean;
  @Input() type: 'normal' | 'warning' = 'normal';

  getButtonClasses() {
    return {
      'action': true,
      'warning': this.type === 'warning',
    }
  }
}
