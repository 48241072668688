import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent  implements OnInit {

  @Input() name: string = '';
  @Input() canDelete: boolean = false;
  @Input() selected: boolean = false;
  constructor() { }

  @Output() deletedTag: EventEmitter<void> = new EventEmitter<void>();
  ngOnInit() {}

  getTagClass() {
    return {
      'tag': true,
      'selected': this.selected
    }
  }
  delete() {
    this.deletedTag?.emit();
  }
}
