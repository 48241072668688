<div id="main">
    <div class="layout" #layout [ngStyle]="getLayoutStyle()">
        @if (content) {
            @switch (content.type) {
                @case ("image") {
                    <img [src]="url" [ngStyle]="getContentStyle()">
                }
                @case ("video") {
                    <app-video #video [sourceUrl]="url" [autoPlay]="false" [ngStyle]="getContentStyle()"></app-video>
                }
                @case ("GIF") {
                    <ion-img [src]="content.gifUrl" [ngStyle]="getContentStyle()"></ion-img>
                }
                @case ("text") {
                    <p>{{content.text}}</p>
                }
                @default {
                    <p>No content here</p>
                }
            }
        }
        @else {
            <ion-spinner name="crescent"></ion-spinner>
        }
        @if (content && content.type)
        {
            <div id="metadatas" [ngStyle]="getMetadatasStyle()">
                <app-overbucks [amount]="content.overbucksDonated" [iconSize]="iconSize" [fontSize]="fontSize" [id]="content.id" [contentId]="content.contentId"></app-overbucks>
                <app-lifetimelabel id="lifetime" [iconSize]="iconSize" [fontSize]="fontSize" [postDate]="content.postDate" [overbucks]="(content.overbucksDonated || 0) + (content.overbucksInvested! || 0)"></app-lifetimelabel>
                <app-share-button id="share-button" [iconSize]="iconSize"></app-share-button>
            </div>
        }
       
    </div>
    @if (content && content.type !== 'text'){
        <p id="caption" [ngStyle]="getCaptionStyle()">{{content.text}}</p>
    }
</div>
