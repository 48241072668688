import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PollChoice } from '@overlie/types';
import { UserService } from '../../../data/user.service';

@Component({
  selector: 'app-poll-choice',
  templateUrl: './poll-choice.component.html',
  styleUrls: ['./poll-choice.component.scss'],
})
export class PollChoiceComponent  implements OnInit, OnChanges {

  @Input() pollId?: string;
  @Input() pollChoice?: PollChoice;
  @Input() color?: string;
  @Input() totalVotes?: number;
  @Input() isWinning?: boolean;
  @Input() displayResults?: boolean;
  @Input() selected?: boolean;

  widthString?: string;

  constructor(private userService: UserService) { }

  ngOnInit()
  {
    this.updateWidthString();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateWidthString()
  }

  selectChoice(event: MouseEvent)
  {
    event.stopPropagation();

    if (this.displayResults || !this.pollId || !this.pollChoice || this.totalVotes == undefined) return;

    this.userService.answerPoll(this.pollId, this.pollChoice.index);
  }

  updateWidthString()
  {
    if (!this.pollChoice || this.totalVotes == undefined || this.totalVotes == null) return;

    this.widthString = this.totalVotes != 0 ? `${Math.round((this.pollChoice.score/this.totalVotes) * 100)}%` : '0%';
  }
}
