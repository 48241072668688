@if (isMobile)
{
  <ion-modal id="sponsor" #sponsor style="&::part(content)
{
    width: 80%;
};">
  <ng-template id="outer-layout">

    <div id="layout">
      <button id="cancel-button" (click)="Close()">
        <ion-icon name="close-outline"></ion-icon>
      </button>
      <h1>Sponsor my post</h1>
      <p>Invest an amount of Overbucks to increase your content’s lifetime and visibility.</p>
      <app-inputfield id="inputfield" #inputField></app-inputfield>
      <p>+ <b>{{ currentInvestedValue | async | oBToLifetime }}</b> hours lifetime</p>
      @if (isSponsoring)
      {
        <p>+ <b>{{ currentInvestedValue | async | oBToReward }}</b> OBs for each view</p>
      }
      <div class="action-buttons">
        <app-action-button class="action-button" text="Sponsor" type="primary" (click)="Confirm()"></app-action-button>
        @if (currentInvestedValue | async) {
          <app-action-button class="action-button" text="Cancel sponsoring" type="tertiary" (click)="Cancel()"></app-action-button>
        }
      </div>
    </div>
  </ng-template>
</ion-modal>
}
@else {
  <ion-modal id="sponsor" #sponsor style="&::part(content)
{
    width: 25%;
};">
  <ng-template id="outer-layout">

    <div id="layout">
      <button id="cancel-button" (click)="Close()">
        <ion-icon name="close-outline"></ion-icon>
      </button>
      <h1>Sponsor my post</h1>
      <p>Invest an amount of Overbucks to increase your content’s lifetime and visibility.</p>
      <app-inputfield id="inputfield" #inputField></app-inputfield>
      <p>+ <b>{{ currentInvestedValue | async | oBToLifetime }}</b> hours lifetime</p>
      @if (isSponsoring)
      {
        <p>+ <b>{{ currentInvestedValue | async | oBToReward }}</b> OBs for each view</p>
      }
      <div class="action-buttons">
        <app-action-button class="action-button" text="Sponsor" type="primary" (click)="Confirm()"></app-action-button>
        @if (currentInvestedValue | async) {
          <app-action-button class="action-button" text="Cancel sponsoring" type="tertiary" (click)="Cancel()"></app-action-button>
        }
      </div>
    </div>
  </ng-template>
</ion-modal>
}

