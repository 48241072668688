import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IonModal, IonSearchbar, Platform } from '@ionic/angular';
import { GifService } from '../../data/gif.service';
import { GIFObject } from 'types/build';
import { MediaObject } from 'types/src';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-gif-modal',
  templateUrl: './gif-modal.component.html',
  styleUrls: ['./gif-modal.component.scss'],
})
export class GifModalComponent  implements OnInit, AfterViewInit {

  @ViewChild('modal') modal?: IonModal;
  @ViewChild('searchBar') searchBar?: IonSearchbar;

  currentGIFS?: GIFObject[]
  selectedGifObject?: BehaviorSubject<GIFObject> = new BehaviorSubject<GIFObject>(null);
  modalBreakpoints = [0, 1];
  height?: number;

  constructor(private gifs: GifService,
    private platform: Platform) { }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.modal?.didDismiss.subscribe(() => this.currentGIFS = []);
  }

  open()
  {
    this.modal?.present();
  }

  searchGIFS()
  {
    if (this.searchBar?.value)
    {
      this.gifs.searchGIFs(this.searchBar.value).subscribe(gifs =>
      {
        this.currentGIFS = gifs;
        this.height = (this.currentGIFS.map(obj =>
        {
          let gifHeight = obj?.mediaFormats['tinygif'].dims[1]!;
          return this.platform.is('mobile') ? gifHeight * 0.9 : gifHeight * 1.15;
        }).reduce((accumulator, currentValue) => 
        {
          return accumulator + currentValue;
        }) / 2)
    })
    }
    else
    {
      this.currentGIFS = [];
    }
  }

  gifClicked(id: string)
  {
    let selectedGif = this.currentGIFS?.find(obj => obj != null && obj.id == id);

    if (selectedGif)
    {
      this.selectedGifObject?.next(selectedGif);
    }
    this.modal?.dismiss();
  }
}
