<ion-fab-button [ngClass]="getButtonClasses('left')" (click)="clickOnSwipeButton('left')">
    <ion-icon name="caret-back-outline"></ion-icon>
</ion-fab-button>
<p [ngClass]="getNewLabelClass('left')"> NEW !</p>

<ion-fab-button [ngClass]="getButtonClasses('right')" (click)="clickOnSwipeButton('right')">
    <ion-icon name="caret-forward-outline"></ion-icon>
</ion-fab-button>
<p [ngClass]="getNewLabelClass('right')"> NEW !</p>

<ion-fab-button [ngClass]="getButtonClasses('top')" (click)="clickOnSwipeButton('top')">
    <ion-icon name="caret-up-outline"></ion-icon>
</ion-fab-button>
<p [ngClass]="getNewLabelClass('top')"> NEW !</p>

<ion-fab-button [ngClass]="getButtonClasses('bottom')" (click)="clickOnSwipeButton('bottom')">
    <ion-icon name="caret-down-outline"></ion-icon>
</ion-fab-button>
<p [ngClass]="getNewLabelClass('bottom')"> NEW !</p>
