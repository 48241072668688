import { Injectable } from '@angular/core';
import { environment } from 'app/src/environments/environment';
import { Observable } from 'rxjs';
import { GIFObject } from 'types/src/Tenor';
import { OverlieHttpService } from './overliehttp.service';

@Injectable({
  providedIn: 'root'
})
export class GifService {

  constructor(private http: OverlieHttpService) { }

  searchGIFs(searchString: string): Observable<GIFObject[]>
  {
    return this.http.get<GIFObject[]>(`${environment.apiUrl}/gifs/search?term=${searchString}`)
  }
}
