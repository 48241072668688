import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stat'
})
export class StatPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    }
    else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'k';
    }
    return value;
  }

}
