import { Component, Input, OnInit } from '@angular/core';
import { Poll } from '@overlie/types';
import { first, map, merge, Observable, skip } from 'rxjs';
import { CommunityService } from '../../../data/community.service';

@Component({
  selector: 'app-polls-list',
  templateUrl: './polls-list.component.html',
  styleUrls: ['./polls-list.component.scss'],
})
export class PollsListComponent  implements OnInit {

  polls?: Observable<Poll[]>

  @Input() communityId?: string;

  constructor(private communityService: CommunityService) { }

  ngOnInit()
  {
    if (!this.communityId) return;

    this.polls = merge(this.communityService.getCommunityPolls(this.communityId).pipe(first()), this.communityService.pollsByCommunity.pipe(skip(1), map(pollsByCommunity => {
      if (!this.communityId) return [];

      return pollsByCommunity.get(this.communityId)!
    })))
  }
}
