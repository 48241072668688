export interface GridConfig {
    gridWidth: number;
    gridHeight: number;
    units: Units;
    minZoom?: number;
    maxZoom?: number;
    paint?: any;
}

export type Units = 'degrees' | 'radians' | 'miles' | 'kilometers';

export type FeatureCollection = {
    type: "FeatureCollection"
}

export interface FillFeatureCollection extends FeatureCollection {
    features: FillFeature[]
}

export interface HeightFeatureCollection extends FeatureCollection {
    features: HeightFeature[]
}

export interface BorderFeatureCollection extends FeatureCollection {
    features: BorderFeature[]
}

export interface CommunityNamesFeatureCollection extends FeatureCollection {
    features: CommunityNameFeature[]
}

export type CompressedMapData = [CompressedFeature]

export type CompressedFeature =
    {
        coordinates: CompressedTileGeometry
        properties: FeatureCoreProperties
    }

export type MapChunk =
    {
        featureCollection: HeightFeatureCollection
    }

export type MapChunkWrapper =
    {
        chunk: MapChunk
        dirty: boolean
    }

export type FeatureCoreProperties =
    {
        communityId: string
        postDate: number
        starsDonated: number
        starsInvested: number
    }

export type FeatureProperties =
    {
        communityId: string
        height: number
        base_height: 0
        color: string
    }

export type Feature = {
    type: "Feature",
    id: WorldPoint,
    properties: FeatureProperties
    geometry: {
        coordinates: Coordinates2D | Coordinates3D
        type: 'LineString' | 'Polygon'
    }
}

export interface FillFeature extends Feature {
    geometry: {
        coordinates: Coordinates2D
        type: 'LineString'
    }
}

export interface HeightFeature extends Feature {
    geometry: {
        coordinates: Coordinates3D
        type: "Polygon"
    },
}

export interface BorderFeature {
    type: "Feature",
    id: string,
    properties: { communityId: string },
    geometry: {
        coordinates: WorldPoint[]
        type: 'LineString'
    }
}

export interface CommunityNameFeature {
    type: "Feature",
    id: string,
    properties:
    {
        communityId: string,
        communityName: string,
        color: string,
        haloColor: 'white' | 'black',
        tileNumber: number;
    },
    geometry:
    {
        coordinates: WorldPoint,
        type: 'Point'
    }
}

export interface SeaRouteObject {
    communityId1: string,
    communityId2: string,
    tile1: [number, number],
    tile2: [number, number],
}

export interface SeaRouteFeature {
    type: 'Feature',
    id: string,
    properties:
    {
        communityId1: string,
        color: string,
        communityId2: string,
    },
    geometry:
    {
        type: 'LineString',
        coordinates: [
            [number, number],
            [number, number],
        ]
    }
}

export interface SeaRouteFeatureCollection extends FeatureCollection {
    features: SeaRouteFeature[]
}

export interface LineStringFeature {
    type: 'Feature',
    id: string,
    properties:
    {
        color: string,
    },
    geometry:
    {
        type: 'LineString',
        coordinates: [
            [number, number],
            [number, number],
        ]
    }
}

export interface LineStringFeatureCollection extends FeatureCollection {
    features: LineStringFeature[]
}

export type Coordinates2D = TileGeometry
export type Coordinates3D = [TileGeometry]

export type CompressedTileGeometry = [WorldPoint, WorldPoint];
export type TileGeometry = [WorldPoint, WorldPoint, WorldPoint, WorldPoint, WorldPoint];
export type TileData = {
    position: WorldPoint;
    postDate: number
    starsDonated: number
    starsInvested: number
    communityId: string;
}
export type WorldPoint = [number, number];

export type TagLink = {
    originCommunityId: string;
    tag: string;
}