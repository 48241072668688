import { CommunityInfos } from "./Community";
import { WorldPoint } from "./MapData";
import { UserMinimalInfos } from "./User";

export type ClientToServerMessage = UserToServerMessage.Message | AuthenticatedUserToServerMessage.Message | AdminToServerMessage.Message;
export namespace ServerToClientMessage {
    export type Message = PingMessage
        | TileAddMessage
        | TileAddAcknowledgeMessage
        | MapDataMessage
        | SeaRoutesMessage
        | BordersMessage
        | CommunitiesMessage
        | NewCommunityMessage
        | DeletedCommunityMessage
        | CommunityMessage
        | FilledTilesDataMessage
        | UserInfosMessage
        | NewClientConnected;

    export interface PingMessage {
        type: 'ping';
    }

    export interface NewClientConnected {
        type: 'newClientConnected';
    }

    export interface TileAddMessage {
        type: 'tileAdd';
        position: any; // TODO set correct type
        postDate: number
        starsDonated: number
        starsInvested: number
        communityId: string;
    }

    export interface TileAddAcknowledgeMessage {
        type: 'tileAddAcknowledge';
        nextTileDate: number;
    }

    export interface MapDataMessage {
        type: 'mapData';
        data: any;
    }
    export interface SeaRoutesMessage {
        type: 'seaRoutes';
        data: any;
    }
    export interface BordersMessage {
        type: 'borders';
        data: any;
    }
    export interface CommunitiesMessage {
        type: 'communities';
        data: any;
    }
    export interface NewCommunityMessage {
        type: 'newCommunity';
        data: {
            community: CommunityInfos,
        }
        playerId: string;
    }
    export interface DeletedCommunityMessage {
        type: 'deletedCommunity';
        communityId: string;
    }
    export interface CommunityMessage {
        type: 'communityMessage';
        message: string;
        username: string;
    }
    export interface FilledTilesDataMessage {
        type: 'filledTilesData';
        data: any;
    }
    export interface UserInfosMessage {
        type: 'playerInfos';
        data: UserMinimalInfos;
    }
}

export namespace ServerToAdminMessage {
    export type Message = ServerToClientMessage.Message;
}

export namespace UserToServerMessage {
    export type Message = PongMessage;
}

export namespace AdminToServerMessage {
    export type Message = PongMessage
        | OpenNewTilesMessage
        | NewBordersMessage
        | ClearEmptyOpenedTilesMessage
        | CommunitiesTileNumbersMessage
        | LandSetMessage;

    export interface OpenNewTilesMessage { // TODO replaced by http fetch ?
        type: 'openNewTiles';
        data: any;
    }

    export interface NewBordersMessage { // TODO replaced by http fetch ?
        type: 'newBorders';
        data: any;
    }

    export interface ClearEmptyOpenedTilesMessage {
        type: 'clearEmptyOpenedTiles';
        data: any;
    }

    export interface CommunitiesTileNumbersMessage {
        type: 'communitiesTileNumbers';
        data: any;
    }
    
    export interface LandSetMessage {
        type: 'landSet';
        land: string;
    }
}

export namespace AuthenticatedUserToServerMessage {
    export type Message = PongMessage
        | TileRequestMessage
        | CommunityMessage;

    export interface TileRequestMessage {
        type: 'tileRequest';
        communityId: string;
        position: WorldPoint;
    }

    export interface CommunityMessage {
        type: 'communityMessage';
        message: string;
    }
}

export interface PongMessage {
    type: 'pong';
}