import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { CapacitorHttp } from '@capacitor/core';
import { environment } from 'app/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OverlieHttpService {

  constructor(private angularHttpClient: HttpClient, private platform: Platform) { }

  get<Type>(url: string): Observable<Type>
  {
    if (this.platform.is("mobile"))
    {
      let result: Subject<Type> = new Subject<Type>();
      CapacitorHttp.get({ url: url, webFetchExtra: { credentials: "include" }}).then(response =>
        {
          if (response.status == 200)
          {
            result.next(response.data);
          }
          else
          {
            result.error('HttpRequestFail')
          }
        })
      return result;
    }
    else
    {
      return this.angularHttpClient.get<Type>(url, environment.httpOptions)
    }
  }

  post<Type>(url: string, body: any): Observable<Type | null>
  {
    if (this.platform.is("mobile"))
    {
      let result: Subject<Type | null> = new Subject<Type | null>();
      fetch(url, {
        method: "POST",
        credentials: "include",
        body: body
      }).then(response => {
        if (response.status == 200)
        {
          result.next(null);
        }
        else
        {
          result.error(response)
        }
      })
      return result;
    }
    else
    {
      return this.angularHttpClient.post<Type>(url, body, environment.httpOptions)
    }
  }

  postJson<Type>(url: string, body: any): Observable<Type | null>
  {
    if (this.platform.is("mobile"))
      {
        let result: Subject<Type | null> = new Subject<Type | null>();
        fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: body
        }).then(response => {
          if (response.status == 200)
          {
            result.next(null);
          }
          else
          {
            result.error('HttpRequestFail')
          }
        })
        return result;
      }
      else
      {
        return this.angularHttpClient.post<Type>(url, body, environment.httpOptions)
      }
  }

  put<Type>(url: string, body: any): Observable<Type | null>
  {
    if (this.platform.is("mobile"))
    {
      let result: Subject<Type | null> = new Subject<Type | null>();
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body)
      }).then(response => {
        if (response.status == 200)
        {
          result.next(null);
        }
        else
        {
          result.error('HttpRequestFail')
        }
      })
      return result;
    }
    else
    {
      return this.angularHttpClient.put<Type>(url, body, environment.httpOptions)
    }
  }
}
