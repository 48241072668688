import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'app/src/app/data/user.service';
import { environment } from 'app/src/environments/environment';
import { CommunityService } from '../../../data/community.service';
import { ContentsService } from 'app/src/app/data/contents.service';
import { IonModal } from '@ionic/angular/common';
import { PersonalContentMetadata, CommunityInfos } from '@overlie/types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-posts-list',
  templateUrl: './user-posts-list.component.html',
  styleUrls: ['./user-posts-list.component.scss'],
})
export class UserPostsListComponent  implements OnInit, AfterViewInit, OnDestroy {
  
  _userId: String | undefined
  _contentsForUser: PersonalContentMetadata[] | undefined
  _communitiesForUser: CommunityInfos[] | undefined
  _contentsByCommunity: {community: CommunityInfos, contents: PersonalContentMetadata[], visible: boolean}[] | undefined

  _contentSponsoSubscription: Subscription | undefined;

  _api_url: string = environment.apiUrl

  @ViewChild('listLayout') listLayout: ElementRef | undefined
  @ViewChild('sponsorModal') sponsorModal: IonModal | undefined

  constructor(private UserService: UserService,
              private CommunityService: CommunityService,
              private ContentsService: ContentsService) { }

  ngOnInit()
  {
    this.UserService.getUserProfile().subscribe(profile =>
    {
      this._userId = profile.id
      this.ContentsService.getContentsForUser(this._userId as string).subscribe(contentsForUser =>
      {
        this._contentsForUser = contentsForUser
        this.CommunityService.communities.subscribe(communities =>
          {
            this._communitiesForUser = Array.from(communities.values())
            const initialValue: any = this._communitiesForUser!.map(communityInfo => ({community: communityInfo, contents: [], visible: true}))
            this._contentsByCommunity = this._contentsForUser!.map(content =>
            {
              let matchingCommunity = this._communitiesForUser?.find(community => community.id == content.communityId)
              return {community: matchingCommunity, content: content}
            }).reduce((accumulator, currentValue) =>
             {
              let result = accumulator.find((obj: { community: CommunityInfos | undefined; }) =>
              {
                if (obj.community && currentValue.community)
                {
                  return obj.community.name == currentValue.community.name
                }

                return false
              })
              
              if (result)
              {
                result.contents.push(currentValue.content)
              }

              return accumulator
            }, initialValue)
          })
        })
      })

      this._contentSponsoSubscription = this.ContentsService.contentSponsorised.subscribe(sponsoInfos =>
      {
        if (this._contentsByCommunity)
        {
          let matchingContent = this._contentsByCommunity.flatMap(obj => obj.contents).find(content => content.id == sponsoInfos.id);
          if (matchingContent)
          {
            (matchingContent.overbucksInvested as number) += sponsoInfos.amount;
            (matchingContent.nbSponsorisations as number)++;
          }
        }
      })
    }

  ngAfterViewInit(): void {
    this.listLayout?.nativeElement.addEventListener('touchstart', (event: any) => {
      event.stopPropagation()
    })
  }

  HideShow(communityName: String)
  {
    let object = this._contentsByCommunity!.find(obj => obj.community.name == communityName)!
    object.visible = !object.visible
  }

  ngOnDestroy(): void {
    this._contentSponsoSubscription?.unsubscribe()
  }
}
