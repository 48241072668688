import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private toast: ToastrService) { }

  public success(title: string, message: string) {
    this.toast.success(message, title);
  }

  public error(title: string, message: string) {
    this.toast.error(message, title);
  }

  public warning(title: string, message: string) {
    this.toast.warning(message, title);
  }
}
