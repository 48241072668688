import { Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { IonMenu, Platform } from '@ionic/angular';
import { Router, RoutesRecognized } from '@angular/router';
import { UserService } from './data/user.service';
import { MapService } from './data/map.service';
import { Observable, Subscription, filter, interval, map, range, timer } from 'rxjs';
import { CommunityService } from './data/community.service';
import { InteractionsService } from './data/interactions.service';
import { Colors } from './utils/Colors';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(public platform: Platform,
    private router: Router,
    private userService: UserService,
    private mapService: MapService,
    private communityService: CommunityService,
    private interactions: InteractionsService) { }
  
  @ViewChild('menu') menu: IonMenu | undefined;


  public username$: Observable<string> = this.userService.userProfile.pipe(map(profile => profile.username));
  public stars$: Observable<number> = this.userService.userProfile.pipe(map(profile => profile.stars));
  public appPages = [
    { title: 'Explore', url: '/explore', icon: 'map' },
    { title: 'Communities', url: '/explore/communities', icon: 'people-circle' },
    { title: 'My posts', url: '/explore/posts', icon: 'albums' },
    { title: 'Support us', url: '/store', icon: 'heart' },
    { title: 'Messages', url: '/messages', icon: 'mail' },
    { title: 'Settings', url: '/settings', icon: 'cog' },
  ];


  public title$: Observable<string> = this.router.events.pipe(filter(event => event instanceof RoutesRecognized), map((data) => (data as RoutesRecognized).state.root.firstChild?.routeConfig?.title as string));
  public showFab = false;
  public fullscreen = false;

  private readonly defaultBackgroundColor = 'var(--background)';
  backgroundColor: string = this.defaultBackgroundColor;

  mapDataSubscription?: Subscription;
  userProfileSubscription?: Subscription;
  routerSubscription?: Subscription;

  ngOnInit() {
    this.interactions.initTimer();
    this.userProfileSubscription = this.userService.userProfile?.subscribe((userProfile) => {
      if (userProfile.communities && userProfile.bookmarks) this.communityService.setUserCommunities(userProfile.communities, userProfile.bookmarks);
    });
    this.mapDataSubscription = this.mapService.getMapData().subscribe();
    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof RoutesRecognized)).subscribe((data) => {
      if ((data as RoutesRecognized).state.root.firstChild && (data as RoutesRecognized).state.root.firstChild!.routeConfig?.data && (data as RoutesRecognized).state.root.firstChild!.routeConfig?.data!['fullscreen']){
        this.fullscreen = (data as RoutesRecognized).state.root.firstChild!.routeConfig?.data!['fullscreen'];
      } else {
        this.fullscreen = false;
      }
      this.showFab = this.fullscreen && !this.platform.is('desktop');  
    });

    this.interactions.contentBackgroundColor$.subscribe((color) => {
      let rgb: {red: number, green: number, blue: number} = Colors.hexToRGB(color);
      this.backgroundColor = `linear-gradient(180deg, rgba(${rgb.red},${rgb.green},${rgb.blue},0.6) 0%, rgba(${rgb.red},${rgb.green},${rgb.blue},0.2) 100%)`;
    });
  }

  ngOnDestroy(): void {
    this.mapDataSubscription?.unsubscribe();
    this.userProfileSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }
}
