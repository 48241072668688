import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MapViewComponent } from './views/map-view/map-view.component';
import { MapOverlayComponent } from './views/map-view/map-overlay/map-overlay.component';
import { SocketIoModule } from 'ngx-socket-io';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { TimerPipe } from './utils/pipes/timer.pipe';
import { CommunitiesComponent } from './views/map-view/communities/communities.component';
import { PostsComponent } from './views/map-view/posts/posts.component';
import { CommunityPopupComponent } from './components/communities/community-popup/community-popup.component';
import { ContentViewComponent } from './views/content-view/content-view.component';
import { MediaContentComponent } from './components/contents/media-content/media-content.component';
import { SwipeOverlayComponent } from './views/content-view/swipe-overlay/swipe-overlay.component';
import { LifetimePipe } from './utils/pipes/lifetime.pipe';
import { ShareButtonComponent } from './components/contents/share-button/share-button.component';
import { LifetimelabelComponent } from './components/contents/lifetimelabel/lifetimelabel.component';
import { UserPostsListComponent } from './components/contents/user-posts-list/user-posts-list.component';
import { VideoComponent } from './components/contents/video/video.component';
import { GoButtonComponent } from './components/contents/go-button/go-button.component';
import { ImageComponent } from './components/contents/image/image.component';
import { SponsorButtonComponent } from './components/contents/sponsor-button/sponsor-button.component';
import { SponsorComponent } from './components/contents/sponsor/sponsor.component';
import { OverbucksComponent } from './components/contents/overbucks/overbucks.component';
import { InputfieldComponent } from './components/utils/inputfield/inputfield.component';
import { OBToRewardPipe } from './utils/pipes/obto-reward.pipe';
import { OBToLifetimePipe } from './utils/pipes/obto-lifetime.pipe';
import { IsMaxSponsoPipe } from './utils/pipes/is-max-sponso.pipe';
import { PostModalComponent } from './views/post-modal/post-modal.component';
import { ActionButtonComponent } from './components/buttons/action-button.component';
import { SelectorComponent } from './components/buttons/selector/selector.component';
import { GifModalComponent } from './views/gif-modal/gif-modal.component';
import { GifUrlPipe } from './utils/pipes/gifurl.pipe';
import { SwipeSlideComponent } from './views/content-view/swipe-slide/swipe-slide.component';
import { CreateCommunityModalComponent } from './views/create-community-modal/create-community-modal.component';
import { CommunityListEntryComponent } from './components/communities/community-list-entry/community-list-entry.component';
import { CommunityListComponent } from './components/communities/community-list/community-list.component';
import { TagComponent } from './components/utils/tag/tag.component';
import { StatPipe } from './utils/pipes/stat.pipe';
import { AvatarComponent } from './components/utils/avatar/avatar.component';
import { SimpleButtonComponent } from './components/buttons/simple-button/simple-button.component';
import { CommunityOverlayComponent } from './views/map-view/community-overlay/community-overlay.component';
import { CommunityDashboardComponent } from './views/map-view/community-overlay/community-dashboard/community-dashboard.component';
import { PollsListComponent } from './components/communities/polls-list/polls-list.component';
import { UsersListComponent } from './components/communities/users-list/users-list.component';
import { PollComponent } from './components/communities/poll/poll.component';
import { PollChoiceComponent } from './components/communities/poll-choice/poll-choice.component';
import { PollCreationComponent } from './components/communities/poll-creation/poll-creation.component';
import { DurationPipe } from './utils/pipes/duration.pipe';
import { ProfileComponent } from './components/profile/profile.component';
import { UserProfileComponent } from './views/map-view/user-profile/user-profile.component';
import { StoreComponent } from './views/store/store.component';
import { ItemComponent } from './views/store/item/item.component';
import { PricePipe } from './utils/pipes/price.pipe';
import { ItemNamePipe } from './utils/pipes/item-name.pipe';
import { ProductSortPipe } from './utils/pipes/product-sort.pipe';
import { BadgeMessageComponent } from './components/utils/badge-message/badge-message.component';

@NgModule({
  declarations: [
    AppComponent,
    MapViewComponent,
    MapOverlayComponent,
    CommunityOverlayComponent,
    CommunityDashboardComponent,
    PollsListComponent,
    UsersListComponent,
    PollComponent,
    PollChoiceComponent,
    PollCreationComponent,
    TimerPipe,
    LifetimePipe,
    CommunitiesComponent,
    CommunityListComponent,
    PostsComponent,
    CommunityPopupComponent,
    CommunityListEntryComponent,
    CreateCommunityModalComponent,
    PostModalComponent, 
    PostsComponent, 
    PostModalComponent,
    GifModalComponent,
    StoreComponent,
    ItemComponent,
    ActionButtonComponent,
    SelectorComponent,
    ContentViewComponent,
    SwipeSlideComponent,
    MediaContentComponent,
    SwipeOverlayComponent,
    ShareButtonComponent,
    LifetimelabelComponent,
    GoButtonComponent,
    VideoComponent,
    GoButtonComponent,
    VideoComponent,
    UserPostsListComponent,
    ImageComponent,
    SponsorButtonComponent,
    OverbucksComponent,
    SponsorComponent,
    InputfieldComponent,
    OBToRewardPipe,
    OBToLifetimePipe,
    IsMaxSponsoPipe,
    GifUrlPipe,
    TagComponent,
    StatPipe,
    AvatarComponent,
    SimpleButtonComponent,
    DurationPipe,
    ProfileComponent,
    UserProfileComponent,
    PricePipe,
    ItemNamePipe,
    ProductSortPipe,
    BadgeMessageComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    SocketIoModule.forRoot(environment.wsConfig),
    IonicModule.forRoot({
      mode: 'ios'
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      progressBar: true,
      progressAnimation: 'increasing',
      closeButton: false,
      extendedTimeOut: 2000,
    }),
    AppRoutingModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent]

})
export class AppModule { }