@if (isMobile)
{
  <ion-modal id="modal" #modal [initialBreakpoint]="modalBreakpoints[1]" [breakpoints]="modalBreakpoints">
    <ng-template>
      <div id="layout">
        <h1>{{ (communityInfos | async)?.name ?? "\<error>" }}</h1>
        <app-community-dashboard [communityId]="communityId"></app-community-dashboard>
      </div>
    </ng-template>
  </ion-modal>
}
@else
{
  <ion-content id="main-container" class="ion-padding">
    <h1>{{ (communityInfos | async)?.name ?? "\<error>" }}</h1>
    <app-community-dashboard [communityId]="communityId"></app-community-dashboard>
  </ion-content>
}
