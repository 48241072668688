import { Injectable} from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'app/src/environments/environment';
import { BehaviorSubject, Observable, Subject, catchError, first, map, of, tap } from 'rxjs';
import { OverlieHttpService } from './overliehttp.service';
import { StandardError, UserPollChoice } from '@overlie/types';
import { AlertService } from '../utils/alert.service';

export interface UserProfile {
  id: string;
  username: string;
  points: number;
  email: string;
  communities?: string[];
  bookmarks?: string[];
  nextTileDate: number;
  tileDelay: number;
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userProfile: BehaviorSubject<UserProfile> = new BehaviorSubject({id: '0', username: '', email: '', points: 0, nextTileDate: 0, tileDelay: 0});
  userPollChoices: BehaviorSubject<UserPollChoice[]> = new BehaviorSubject<UserPollChoice[]>([]);
  newPollChoice: Subject<UserPollChoice> = new Subject<UserPollChoice>()

  constructor(private http: OverlieHttpService, private platform: Platform, private alert: AlertService) { }

  isAuthenticated(): Observable<boolean> {
    return this.getUserProfile().pipe(
      map(userInfos => userInfos !== undefined),
      catchError(() => {
        return of(false);
      }));
  }

  getUserProfile(): Observable<UserProfile> {
      return this.http.get<UserProfile>(`${environment.apiUrl}/infos/user`).pipe(
        tap((userInfos) => {
            this.userProfile.next(userInfos);
            
            if (userInfos)
            {
              this.getUserPollChoices();
            }
      }))
  };

  updateNextTileDate(value: number) {
    this.userProfile.next({...this.userProfile.value, nextTileDate: value});
  }

  updateUserCommunities(communityId: string)
  {
    this.userProfile.value.communities?.push(communityId)
  }

  answerPoll(pollId: string, index: number)
  {
    this.http.post<UserPollChoice>(`${environment.apiUrl}/infos/answer-poll`, { pollId: pollId, userId: this.userProfile.value.id, index: index } )
      .pipe(catchError((e) => {
        return of(new StandardError(e.error))
      })).subscribe(v => {
        if (!v) return;

        if (v instanceof StandardError) {
          this.alert.error("Couldn't register vote.", v.errorMsg)
          return;
        }

        let tmp = this.userPollChoices.value
        tmp.push(v)
        this.userPollChoices.next(tmp);
        this.newPollChoice.next(v)
      })
  }

  getUserPollChoices()
  {
    this.http.get<UserPollChoice[]>(`${environment.apiUrl}/infos/poll-choices`)
      .pipe(first(), catchError((e) => {
        return of(new StandardError(e.error))
      })).subscribe(value => {
        if (value instanceof StandardError)
        {
          this.alert.error("Error", value.errorMsg)
          return
        }

        this.userPollChoices.next(value)
      })
  }
}
