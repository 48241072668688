<div id="listLayout" #listLayout>
@for (contentsObj of _contentsByCommunity; track $index)
{
  @if (contentsObj.contents.length > 0)
  {
    <button id="header-button" (click)="HideShow(contentsObj.community.name)">
      <div id="title-layout">
        <ion-icon id="header-icon" name="remove-outline"></ion-icon>
        <p id="header-text">{{ contentsObj.community.name }}</p>
      </div>
      <div id="nb-posts-layout">
        <p id="nb-posts">{{ contentsObj.contents.length }}</p>
      </div>
    </button>
    @if (contentsObj.visible)
    {
      @for (content of contentsObj.contents; track $index)
      {
        <div id="post-container">

          @switch (content.type)
          {
            @case("video")
            {
              <app-video id="content"
                [autoPlay]="false"
                [sourceUrl]="_api_url + '/contentpayload?coordinates=' + content.id">
              </app-video>
            }
            @case("image")
            {
              <app-image id="content" [sourceUrl]="_api_url + '/contentpayload?coordinates=' + content.id"/>
            }
            @case ("GIF")
            {
              <app-image id="content" [sourceUrl]="content.gifUrl"/>
            }
          }
          <p id="post-caption">{{ content.text }}</p>
          <app-go-button id="go" [coordinates]="content.id"></app-go-button>
          <app-sponsor-button id="sponsor" [contentId]="content.id" [isSponsoring]="true" [disabled]="content.nbSponsorisations | isMaxSponso"></app-sponsor-button>
          <app-overbucks [amount]="content.overbucksDonated"></app-overbucks>
          <app-lifetimelabel id="lifetime" [postDate]="content.postDate" [overbucks]="(content.overbucksDonated || 0) + (content.overbucksInvested || 0)"></app-lifetimelabel>
          <app-share-button id="share-button"></app-share-button>
        </div>
        <div id="separator"></div>
      }
    }
  }
}
</div>