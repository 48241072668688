import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'app/src/environments/environment';
import { BehaviorSubject, Observable, Subject, catchError, filter, first, map, of, tap } from 'rxjs';
import { OverlieHttpService } from './overliehttp.service';
import { StandardError, UserMinimalInfos, UserPersonalInfos, UserPollChoice, WaitingForSponsoredCommunityCreation } from '@overlie/types';
import { AlertService } from '../utils/alert.service';


const defaultUserProfile: UserPersonalInfos = { id: '', username: '', email: '', stars: 0, badges: [], communities: [], bookmarks: [], tileDelay: 0, nextTileDate: 0, isAdmin: false, isSubscribed: false, quitCommunityTickets: 0, waitingForSponsoredCommunityCreation: undefined };

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userProfile: BehaviorSubject<UserPersonalInfos> = new BehaviorSubject(defaultUserProfile);
  userPollChoices: BehaviorSubject<UserPollChoice[]> = new BehaviorSubject<UserPollChoice[]>([]);
  newPollChoice: Subject<UserPollChoice> = new Subject<UserPollChoice>()

  constructor(private http: OverlieHttpService, private platform: Platform, private alert: AlertService) { }

  isAuthenticated(): Observable<boolean> {
    return this.getSelfProfile().pipe(
      map(userInfos => userInfos !== undefined),
      catchError(() => {
        return of(false);
      }));
  }

  getSelfProfile(): Observable<UserPersonalInfos> {
    return this.http.get<UserPersonalInfos>(`${environment.apiUrl}/infos/user`).pipe(
      tap((userInfos) => {
        this.userProfile.next(userInfos);

        if (userInfos) {
          this.getUserPollChoices();
        }
      }))
  };

  getUserProfile(userId: string): Observable<UserMinimalInfos> {
    return this.http.get<UserMinimalInfos>(`${environment.apiUrl}/infos/user/${userId}`);
  }

  updateNextTileDate(value: number) {
    this.userProfile.next({ ...this.userProfile.value, nextTileDate: value });
  }

  updateUserCommunities(communityId: string) {
    this.userProfile.value.communities?.push(communityId)
  }

  answerPoll(pollId: string, index: number) {
    this.http.post<UserPollChoice>(`${environment.apiUrl}/infos/answer-poll`, { pollId: pollId, userId: this.userProfile.value.id, index: index })
      .pipe(catchError((e) => {
        return of(new StandardError(e.error))
      })).subscribe(v => {
        if (!v) return;

        if (v instanceof StandardError) {
          this.alert.error("Couldn't register vote.", v.errorMsg)
          return;
        }

        let tmp = this.userPollChoices.value
        tmp.push(v)
        this.userPollChoices.next(tmp);
        this.newPollChoice.next(v)
      })
  }

  getUserPollChoices() {
    this.http.get<UserPollChoice[]>(`${environment.apiUrl}/infos/poll-choices`)
      .pipe(first(), catchError((e) => {
        return of(new StandardError(e.error))
      })).subscribe(value => {
        if (value instanceof StandardError) {
          this.alert.error("Error", value.errorMsg)
          return
        }

        this.userPollChoices.next(value)
      })
  }

  editUserProfile(edit: { avatar?: string, username?: string, bio?: string }): Observable<UserPersonalInfos | null> {
    return this.http.post<UserPersonalInfos | null>(`${environment.apiUrl}/infos/user/edit`, edit)
      .pipe(
        filter(v => v !== null),
        tap((userInfos) => {
          this.userProfile.next(userInfos!);
        }));
  }
}
