<div id="list-container">
  <div id="joined-container">
    <button class="header-button" (click)="HideShowJoined()">
      <div class="title-layout">
        <ion-icon class="header-icon" [name]="expandJoined ? 'caret-down-outline' : 'caret-forward-outline'"></ion-icon>
        <p class="header-text">Joined</p>
      </div>
      <div class="nb-communities-layout">
        <p class="nb-communities">{{ (joinedCommunities | async)?.length }} / {{ joinLimit }}</p>
      </div>
    </button>
    @if (expandJoined)
    {
      @for(community of joinedCommunities | async; track $index)
      {
        <app-community-list-entry [communityImage]="community.image" [communityName]="community.name" [communityId]="community.id" [isBookmark]="false"></app-community-list-entry>
      }
    }
  </div>
  <div id="bookmarked-container">
    <button class="header-button" (click)="HideShowBookmarked()">
      <div class="title-layout">
        <ion-icon class="header-icon" [name]="expandBookmarks ? 'caret-down-outline' : 'caret-forward-outline'"></ion-icon>
        <p class="header-text">Bookmarks</p>
      </div>
      <div class="nb-communities-layout">
        <p class="nb-communities">{{ (bookmarkedCommunities | async)?.length }}</p>
      </div>
    </button>
    @if (expandBookmarks)
    {
      @for(community of bookmarkedCommunities | async; track $index)
      {
        <app-community-list-entry [communityImage]="community.image" [communityName]="community.name" [communityId]="community.id" [isBookmark]="true"></app-community-list-entry>
      }
    }
  </div>
</div>
