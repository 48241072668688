import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  @Input() image: string | Blob | undefined;
  @Input({ required: true }) type: 'user' | 'community' = 'user';

  getAvatarStyle() {
    return {
      borderRadius: this.type === 'user' ? '50%' : '6px',
    }
  }

  getImageStyle() {
    return {
      width: this.image ? '100%' : '50%',
      objectFit: this.image ? 'cover' : 'contain',
    }
  }
}
