<ion-modal #modal [initialBreakpoint]="modalBreakpoints[1]" [breakpoints]="modalBreakpoints">
  <ng-template>
    <div #layout class="layout" [ngClass]="{'layout-text': (selector.selected | async )?.value === 'text'}">
      <div class="header">
        @switch ((selector.selected | async )?.value) {
          @case ("media") {
          <div class="media">
            @if (media && media.src) {
            <div class="media-container" (click)="toggleRemoveButton()">
              @switch (media.mediaType) {
              @case ("image") {
              <ion-img class="media-content" [src]="media.src">
              </ion-img>
              }
              @case ("video") {
              <video class="media-content" [src]="media.src" controls>
              </video>
              }
              }
              <ion-button #removeButton class="remove-button" (click)="removeMedia()">
                <ion-icon name="close-outline"></ion-icon>
              </ion-button>
            </div>
            } @else {
            <ion-icon name="cloud-upload-outline" class="upload-icon"></ion-icon>
            <ion-button (click)="uploadMedia()">Upload media</ion-button>
            }
          </div>
          }
          @case ("text") {
          <div class="text-content">
            <div class="text-header">
              <h3>Preview</h3>
            </div>
            <div class="text-preview-area" [style]="'background-color: ' + (community?.color || '#fff')">
              <p>{{description.value}}</p>
            </div>
            <div class="text-options">
              <ion-button disabled="true" class="text-option" #fontOption>
                <ion-icon name="text-outline"></ion-icon>        
              </ion-button>
              <ion-button disabled="true" class="text-option" #backgroundColorOption>
                <ion-icon name="color-fill-outline"></ion-icon>    
              </ion-button>
              <ion-button disabled="true" class="text-option" #fontStylingOption>
                <ion-icon name="color-wand-outline"></ion-icon>    
              </ion-button>
            </div>
          </div>
          }
          @case("gif")
          {
            @if (gifObject | async)
            {
              <ion-img class="media-content" [src]="gifObject | async | gifurl:'tinygif'">
              </ion-img>
            }
          }
          }
      </div>

      <div class="content">
        <div class="main">
          <div>Description
            <span class="label">{{ description.value?.length }}/{{ description.maxlength }}</span>
          </div>
          <ion-textarea #description maxlength="100" placeholder="Add text to your publication"></ion-textarea>
        </div>
        <app-selector #selector class="selector" [options]="selectorOptions"></app-selector>
      </div>
      <div class="footer">
        <app-action-button type="secondary" [text]="(sponsorValue | async)! > 0 ? (sponsorValue | async) + ' OBs' : 'Sponsor'" (click)="openSponsorModal()"></app-action-button>
        <app-action-button type="primary" text="Post" [loading]="postButtonLoading" (click)="post()"></app-action-button>
      </div>
    </div>
  </ng-template>
</ion-modal>
<app-sponsor #sponsorModal [isPosting]="true"></app-sponsor>
<app-gif-modal #gifModal></app-gif-modal>