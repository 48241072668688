<div id="main-container" [ngStyle]="{ scale: (selectedTag | async) ? '0.7' : '1' }">
  @if (communitiesInfos) {
    <div id="community-stats">
      <div id="members" class="stat">
        <img src="/assets/icon/community.svg" alt="">
        <span>{{communitiesInfos.nbUsers | stat}}</span>
      </div>
      <div id="bookmarks" class="stat" (click)="updateBookmark($event)">
        <ion-icon [name]="'bookmark' + ((bookmarked | async) ? '' : '-outline')"></ion-icon>
        <span>{{ communitiesInfos.nbBookmarks | stat}}</span>
      </div>
    </div>
    <h1 id="title">{{communitiesInfos.name}}</h1>
    <div class="secondary" id="desc">
      @if (communitiesInfos.description) {
        {{ communitiesInfos.description }}
      } @else {
        <ion-note>No description</ion-note>
      }
    </div>
    <div id="avatar">
      <app-avatar class="avatar" [image]="communitiesInfos.image" [type]="'community'"></app-avatar>
    </div>
    <div id="tile-number-layout">
      <div id="community-color" [ngStyle]="{ 'background': communitiesInfos!.color }"></div>
      <p id="tile-number">{{ (communitiesInfos.tileNumber ? communitiesInfos.tileNumber : 0) | stat }}</p>
    </div>
  
    <div id="actions">
      <app-simple-button id="see" class="action" text="See" (click)="seeCommunityPost($event)"></app-simple-button>
      @if(isMember) {
        <app-simple-button id="leave" class="action" text="Leave" type="warning" (click)="leaveCommunity()"></app-simple-button>
      } @else {
        <app-simple-button id="join" class="action" text="Join" (click)="joinCommunity()"></app-simple-button>
      }
    </div>
    <div id="tags">
      @for(tag of communitiesInfos.tags; track $index)
      {
        <app-tag [name]="tag" [canDelete]="false" [selected]="(selectedTag | async)?.tag === tag" (click)="showRelatedCommunities($event, tag)"></app-tag>
      }
    </div>
  }
</div>