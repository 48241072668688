<div id="main-container">
  <div id="poll-general-infos">
    <ion-textarea #titleInput id="title-input" class="input" maxlength="100" placeholder="Enter poll title" [autoGrow]="true" [counter]="false" (keyup.enter)="confirmChoice()" ></ion-textarea>
    <input #colorPicker id="color-picker"  type="color" [value]="colorValue" (input)="updateColorValue()">
  </div>
  
  @for(choice of choices; track $index)
  {
    <div class="choice">
      <p>{{ choice }}</p>
      <ion-button class="removeChoice" (click)="removeChoice($index)">
        <ion-icon name="trash-outline"></ion-icon>
      </ion-button>
    </div>
  }
  @if (choices && choices.length < 4)
  {
    <ion-input #choiceTitle id="choice-input" class="input" maxlength="30" [placeholder]="'Answer ' + (choices.length + 1)" [counter]="true" (keyup.enter)="confirmChoice()" ></ion-input>
  }
  <ion-button id="confirm" [disabled]="creationDisabled" (click)="createPoll()">
    Confirm
  </ion-button>
</div>
