import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { InteractionsService } from 'app/src/app/data/interactions.service';
import { MapService } from 'app/src/app/data/map.service';
import { TileData, WorldPoint } from '@overlie/types';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CommunityService } from 'app/src/app/data/community.service';
import { MapUtils } from 'app/src/app/utils/MapUtils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-community-list-entry',
  templateUrl: './community-list-entry.component.html',
  styleUrls: ['./community-list-entry.component.scss'],
})
export class CommunityListEntryComponent  implements OnInit, AfterViewInit {

  @Input() communityId?: string;
  @Input() communityName?: string;
  @Input() communityImage?: string;
  @Input() nbNewContent?: number
  @Input() isBookmark?: boolean

  communityLeft?: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  private tiles!: TileData[];
  private selectedTile?: WorldPoint;
  private endFocusSub?: Subscription;

  constructor(private mapService: MapService,
    private interactions: InteractionsService,
    private communityService: CommunityService,
    private router: Router,) { }

  ngOnInit()
  {
    this.endFocusSub = this.mapService.endFocused!.subscribe((value) => 
      {
        if (value)
        {
          this.goToContent()
        }
      })
  }

  ngAfterViewInit() {
      this.mapService.mapData!.subscribe((md) => 
      {
        if (md) {
          this.tiles = md.tiles.filter(tile => tile.communityId == this.communityId);
        }
      })
  }

  SeeContent()
  {
    let i = Math.floor(Math.random() * this.tiles.length);
    this.selectedTile = this.tiles[i].position;
    
    this.mapService.goToCoordinates([this.tiles[i].position[0], this.tiles[i].position[1]]);
  }

  SeeCommunity()
  {
    let allLongs = this.tiles.map(t => t.position[0])
    let allLats = this.tiles.map(t => t.position[1])

    let allLongsSorted = allLongs.sort((l1, l2) => l1 - l2)
    let allLatsSorted = allLats.sort((l1, l2) => l1 - l2)

    allLongsSorted.push(allLongsSorted[allLongsSorted.length - 1] + MapUtils.TILES_OFFSET_LONG)
    allLatsSorted.push(allLatsSorted[allLatsSorted.length - 1] + MapUtils.TILES_OFFSET_LAT)

    this.mapService.focusArea([
      [allLongsSorted[0], allLatsSorted[0]],
      [allLongsSorted[allLongsSorted.length - 1], allLatsSorted[allLatsSorted.length - 1]]
    ])

    this.router.navigate([`/explore/community/${this.communityId}`]);
  }

  goToContent()
  {
    if (!this.selectedTile) return;

    this.mapService.selectNewTile(this.selectedTile);
    this.interactions.sendSignal<boolean>(this.interactions.viewTileButtonClicked, true);
  }

  Leave()
  {
    if (!this.communityId) return;

    if (this.isBookmark)
    {
      this.communityService.remBookmark(this.communityId)
    }
    else
    {
      this.communityService.leaveCommunity(this.communityId);
    }

    this.endFocusSub?.unsubscribe();
  }
}
