<button id="button" [class]="type" [disabled]="disabled || loading">
    @if (loading) {
      <ion-spinner name="crescent"></ion-spinner>
    }
    @else {
      <div id="text" [class]="'text-'+type">
        {{text}}
      </div>
    }
</button>
