import { WorldPoint } from "./MapData"

/**
 * Database content schema
 */
export interface ICommunitySchema
{
    _id: string,
    _name: string,
    _color: string,
    _description: string,
    _image: string | undefined,
    _nbUsers: number,
    _nbBookmarks: number,
    _tileNumber: number,
    _creatorId: string,
    _tags: string[],
    _firstTilePosition: WorldPoint,
    _creationDate: number
}
export interface CommunityCreatePOST {
    name: string
    color: string
    tags: string[]
    description: string,
    firstTilePosition: WorldPoint,
    image: string | undefined
}
export interface CommunityCreationInfos extends CommunityCreatePOST
{
    tileNumber: number
    nbUsers: number
    nbBookmarks: number
    creatorId: string,
}

export type CommunityInfos = Omit<CommunityCreationInfos & { id: string }, "firstTilePosition">

export class Poll
{
    _id: string;
    communityId: string;
    userId: string;
    color: string;
    title: string;
    choices: PollChoice[];
    nbVoters: number;
    postDate: number;

    constructor(pollId: string, communityId: string, userId: string, color: string, title: string, choices: PollChoice[], nbVoters: number, postDate: number)
    {
        this._id = pollId;
        this.communityId = communityId;
        this.userId = userId;
        this.color = color;
        this.title = title;
        this.choices = choices;
        this.nbVoters = nbVoters;
        this.postDate = postDate;
    }
}

export class PollChoice
{
    title: string;
    score: number;
    index: 1 | 2 | 3 | 4;

    constructor(title: string, score: number, index: 1 | 2 | 3 | 4)
    {
        this.title = title;
        this.score = score;
        this.index = index;
    }
}

export class UserPollChoice extends PollChoice
{
    pollId: string;
    userId: string;

    constructor(userId: string, pollId: string, title: string, score: number, index: 1 | 2 | 3 | 4)
    {
        super(title, score, index)

        this.pollId = pollId;
        this.userId = userId;
    }
}