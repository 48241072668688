@if (isMobile)
{
  <ion-modal id="modal" #modal [initialBreakpoint]="modalBreakpoints[1]" [breakpoints]="modalBreakpoints">
    <ng-container *ngIf="user$ | async as user">
        <app-profile [type]="'user'" [user]="user"></app-profile>
    </ng-container>
  </ion-modal>
}
@else
{
  <div #mainContainer id="profile-main-container" class="ion-padding">
    <ng-container *ngIf="user$ | async as user">
        <app-profile [type]="'user'" [user]="user"></app-profile>
    </ng-container>
  </div>
}
