import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'oBToLifetime'
})
export class OBToLifetimePipe implements PipeTransform {

  transform(value: number | null, ...args: unknown[]): number {
    if (value)
    {
      return (value * 36) / 3600
    }

    return 0;
  }

}
