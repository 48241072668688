import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../data/user.service';
import { map, Observable } from 'rxjs';
import { UserMinimalInfos } from 'types/build';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {

  constructor(private userService: UserService, 
    private route: ActivatedRoute,
    private platform: Platform) { }

  modalBreakpoints = [0, 0.5, 1];
  userId?: string;
  isMobile: boolean = false;
  user$?: Observable<UserMinimalInfos>;

  ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get('userId')!;
    if (!this.userId) this.userId = this.userService.userProfile.value.id;
    this.isMobile = this.platform.is('mobile');
    this.user$ = this.userService.getUserProfile(this.userId);
  }

}
