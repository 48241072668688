import { Pipe, PipeTransform } from '@angular/core';
import { GIFObject } from 'types/build';

@Pipe({
  name: 'gifurl'
})
export class GifUrlPipe implements PipeTransform {

  transform(value: GIFObject, ...args: any): unknown {
    if (value?.mediaFormats[args])
    {
      return value.mediaFormats[args].url
    }

    return "";
  }
}
