import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, IonTextarea } from '@ionic/angular';
import { CommunityService } from '../../../data/community.service';
import { Colors } from 'app/src/app/utils/Colors';

@Component({
  selector: 'app-poll-creation',
  templateUrl: './poll-creation.component.html',
  styleUrls: ['./poll-creation.component.scss'],
})
export class PollCreationComponent implements OnInit {

  @Input() communityId?: string;

  @ViewChild('colorPicker') colorPicker!: ElementRef<HTMLInputElement>;
  @ViewChild('choiceTitle') choiceTitle!: IonInput;
  @ViewChild('titleInput') pollTitle!: IonTextarea;

  colorValue?: string;
  choices?: string[] = []
  creationDisabled?: boolean

  constructor(private communityService: CommunityService) { }

  ngOnInit()
  {
    this.colorValue = Colors.getRandomHexColor();
    this.creationDisabled = true;
  }

  updateColorValue()
  {
    this.colorValue = this.colorPicker?.nativeElement.value ?? '#000000'
  }

  confirmChoice()
  {
    if (!this.choiceTitle.value || !this.choices) return;

    this.choices?.push(this.choiceTitle.value as string)

    this.choiceTitle.value = ""

    this.creationDisabled = this.choices.length < 2
  }

  removeChoice(index: number)
  {
    if (!this.choices) return;

    this.choices = this.choices.filter((_, i) => i != index)

    this.creationDisabled = this.choices.length < 2
  }

  createPoll()
  {
    if (!this.communityId || !this.colorValue || !this.pollTitle.value || !this.choices) return

    this.communityService.createPoll(this.communityId, this.colorValue, this.pollTitle.value, this.choices)

    this.clearAll()
  }

  clearAll()
  {
    this.creationDisabled = true;
    this.choices = []
    this.pollTitle.value = ""
    this.choiceTitle.value = ""
    this.colorValue = Colors.getRandomHexColor();
  }
}
