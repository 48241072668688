import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-community-dashboard',
  templateUrl: './community-dashboard.component.html',
  styleUrls: ['./community-dashboard.component.scss'],
})
export class CommunityDashboardComponent  implements OnInit {

  currentPage?: 'polls' | 'users' | 'chat';
  @Input() communityId?: string;

  constructor() { }

  ngOnInit()
  {
    this.currentPage = "polls"
  }

  changePage(event: CustomEvent)
  {
    this.currentPage = event.detail.value
  }
}
