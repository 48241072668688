import { SocketIoConfig } from "ngx-socket-io";

const socketConf : SocketIoConfig = {
  url: 'https://dev.overlie.net',
  options: {
    path: '/api/v1/ws',
    withCredentials: true
  }
};

const httpOptions = {
  withCredentials: true
};

export const environment = {
  production: true,
  apiUrl: 'https://dev.overlie.net/api/v1',
  appUrl: 'https://dev.overlie.net',
  wsConfig: socketConf,
  httpOptions: httpOptions,
  maxSponsos: 3,
  maxJoin: 3,
  maxTags: 5,
  starValueInMS: 36000,
  defaultLifetimeInMS: 10 * 3600 * 1000,
  pollVoteDelay: 24 * 60 * 60 * 1000,
  oauthAppId: {
    google: {
      web: "1092942480161-tc9j37etsm42b1smka66auf7kuqpvvmt.apps.googleusercontent.com",
      android: "1092942480161-0q1sdqr25j78ejhfd8evnlfkhu5mecim.apps.googleusercontent.com",
      ios: "1092942480161-9egsmhhpqmtgrbqfv14bgvqd7lpv1r84.apps.googleusercontent.com"
    }
  }
};