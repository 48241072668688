import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription, filter, first } from 'rxjs';
import { ContentMetadata, ContentType, MediaType } from '@overlie/types';
import { ContentsService } from 'app/src/app/data/contents.service';
import { VideoComponent } from '../video/video.component';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-media-content',
  templateUrl: './media-content.component.html',
  styleUrls: ['./media-content.component.scss']
})
export class MediaContentComponent  implements OnInit, OnChanges
{
  contentSubscription: Subscription | undefined;

  @ViewChild("layout") layout!: ElementRef;
  @ViewChild("video") video!: VideoComponent;

  @Input({ required: true }) content!: ContentMetadata | null;

  iconSize: number = 35;
  fontSize: number = 20;

  url?: string;

  constructor(private contentService: ContentsService,
              private platform: Platform) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['content'] && changes['content'].currentValue) {
        this.content = changes['content'].currentValue;
        if (!this.content) return;
        
        if (this.content.type && this.content.id && (this.content.type === ContentType.Image || this.content!.type === ContentType.Video)) {
          this.url = this.contentService.getContentPayloadURL(this.content.id);
        }

        this.contentSubscription?.unsubscribe();
        this.contentSubscription = this.contentService.contentSponsorised.pipe(filter(obj => obj.id == this.content?.id)).subscribe(obj => {
          if (this.content?.overbucksDonated !== undefined)
          {
            this.content.overbucksDonated += obj.amount
          }
        });
      }
  }

  getMetadatasStyle()
  {
    if (this.platform.is('desktop'))
    {
      return {
        position: 'absolute',
        height: '30%',
        right: '5%',
        marginBottom: '50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        alignSelf: 'flex-end',
      }
    }
    else
    {
      return {
        position: 'absolute',
        height: '30%',
        marginBottom: '50px',
        marginLeft: '7px',
        right: '5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        alignSelf: 'flex-end',
      }
    }
  }

  getContentStyle()
  {
    if (this.platform.is('desktop'))
    {
      return {
        width: '100%',
        height: '100%',
      }
    }
    else
    {
      return {
        position: 'absolute',
        width: '100%',
        height: '100%',
      }
    }
  }

  getLayoutStyle()
  {
    if (this.platform.is("desktop"))
    {
      return {
        position: 'relative',
        height: '80vh',
        width: '100%',
        top: '10vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    }
    else
    {
      return {
        position: 'relative',
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    }
  }

  getCaptionStyle()
  {
    if (this.platform.is('desktop'))
    {
        return {
          display: 'block',
          position: 'relative',
          top: '10vh',
          left: '10%'
      }
    }
    else
    {
      return {
        position: 'absolute',
        bottom: '5%',
        marginLeft: '20px',
        marginRight: '20px'
      }
    }
  }
}
