<div id="profile-layout">
  <div id="profile-header">
    <div id="avatar">
      @if (canEdit) {
      <ion-button class="edit-image" (click)="editAvatar()">
        <ion-icon name="create-outline"></ion-icon>
      </ion-button>
      }
      @if (community) {
      <app-avatar class="avatar" [image]="community.image" [type]="type"></app-avatar>
      } @else if (user) {
      <app-avatar class="avatar" [image]="'https://ui-avatars.com/api/?name='+user.username.replace('','+')"
        [type]="type"></app-avatar>
      }
    </div>
    <div id="name">
      <h1>{{ type === "community" ? community?.name : user?.username }}</h1>
    </div>
    @if (community) {
    <div id="stats">
      <div id="members" class="stat">
        <img src="/assets/icon/community.svg" alt="">
        <span>{{community.nbUsers | stat}}</span>
      </div>
      <div id="bookmarks" class="stat">
        <ion-icon [name]="'bookmark-outline'"></ion-icon>
        <span>{{ community.nbBookmarks | stat}}</span>
      </div>
      <div id="tiles" class="stat">
        <div id="community-color" [ngStyle]="{ 'background': community.color }"></div>
        <span>{{ community.tileNumber | stat}}</span>
      </div>
    </div>
    } @else if (user) {
      <div class="badges">
        BADGES
        <!-- DISPLAY BADGES HERE -->
      </div>
    }
  </div>
  @if (community) {
  <div id="profile-description">
    <ion-textarea #description rows="1" [ngClass]="{ 'editable': canEdit, 'edit-description': editDescriptionMode }"
      autoGrow="true" maxlength="150" [readonly]="!canEdit || !editDescriptionMode" (click)="enterEditDescriptionMode()"
      [value]="community.description"></ion-textarea>
    @if (canEdit && editDescriptionMode) {
    <div class="confirm-cancel">
      <ion-button class="action-button" fill="clear" (click)="cancelEditDescription()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
      <ion-button class="action-button" fill="clear" (click)="editDescription()">
        <ion-icon name="checkmark-outline"></ion-icon>
      </ion-button>
    </div>
    }
  </div>
  }
  @if (community) {
  <div id="tags" [ngClass]="{ 'editable': canEdit, 'edit-tags': editTagsMode }" (click)="enterEditTagsMode()">
    @for (tag of community.tags; track $index) {
    <app-tag [name]="tag" [canDelete]="editTagsMode" [selected]="(selectedTag | async)?.tag === tag"
      (deletedTag)="deleteTag(tag)" (click)="showRelatedCommunities(tag, $event)"></app-tag>
    }
    @if (canEdit && editTagsMode) {
    <div #tagSelection id="tag-selection">
      <ion-input #tagInput class="input" (keyup.enter)="addTag()" (keyup.space)="addTag()"
        (ionInput)="onTagInput($event)" [disabled]="tagsLimit" maxlength="15"
        placeholder="Add tags to the community (max 5)" type="text" [counter]="true"></ion-input>
      <button id="confirm-tag" (click)="addTag()" [disabled]="tagsLimit">
        <ion-icon color="primary" name="Add"></ion-icon>
      </button>
    </div>
    }
  </div>
  @if (canEdit && editTagsMode) {
  <div class="confirm-cancel">
    <ion-button class="action-button" fill="clear" (click)="cancelEditTags()">
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>
    <ion-button class="action-button" fill="clear" (click)="editTags()">
      <ion-icon name="checkmark-outline"></ion-icon>
    </ion-button>
  </div>
  }
  }
  @if (user) {
    <div id="user-communities">
      <h4>Communities</h4>
    
      @for (community of (userCommunities | async); track $index) {
        <div class="community-container" (click)="seeCommunity(community.id)">
          <app-avatar class="community-avatar" type="community" [image]="community.image"></app-avatar>
          <div>
            <div class="community-name">{{ community.name }}</div>
            <div class="community-role">{{ communityRoles[community.id] }}
              @switch (communityRoles[community.id]!) {
                @case ('Creator') {
                <img src="assets/icon/crown.svg" class="user-icon" alt="Creator" />
                }
                @case ('Administrator') {
                <ion-icon name="shield" class="user-icon" alt="Administrator"></ion-icon>
                }
                @case ('Moderator') {
                <ion-icon name="shield-half" class="user-icon" alt="Moderator"></ion-icon>
                }
                }
            </div>
          </div>
        </div>
      }
    </div>
  }
</div>