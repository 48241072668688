import { CommunityInfos, FillFeature, HeightFeature, ServerToClientMessage, TileData, TileGeometry, WorldPoint } from "@overlie/types";
import { environment } from "app/src/environments/environment";

export module MapUtils {
  export const TILES_OFFSET_LONG = 0.0008993203637235325;
  export const TILES_OFFSET_LAT = 0.0008993203637217562;
  export const APPROXIMATE_CENTER_OFFSET: number = 0.00044966018186226905;
  export const TOLERANCE = 1e-6;

  export function arePointsEquals(worldPoint1: WorldPoint, worldPoint2: WorldPoint) {
    return Math.abs(worldPoint1[0] - worldPoint2[0]) < TOLERANCE
      && Math.abs(worldPoint1[1] - worldPoint2[1]) < TOLERANCE;
  }

  export function areCoordEquals(n1: number, n2: number) {
    return Math.abs(n1 - n2) < TOLERANCE;
  }

  export function buildNewHeightFeature(datas: ServerToClientMessage.TileAddMessage, community: CommunityInfos): HeightFeature {
    let color = 'none'
    if (community) {
      color = community.color;
    }
    return {
      type: "Feature",
      properties: {
        height: calculateTileHeight(datas.postDate, datas.starsDonated, datas.starsInvested),
        base_height: 0,
        color: color,
        communityId: community.id
      },
      geometry: {
        coordinates: [[
          datas.position,
          [datas.position[0], datas.position[1] + TILES_OFFSET_LAT],
          [datas.position[0] + TILES_OFFSET_LONG, datas.position[1] + TILES_OFFSET_LAT],
          [datas.position[0] + TILES_OFFSET_LONG, datas.position[1]],
          datas.position
        ]],
        type: "Polygon"
      },
      id: datas.position
    }
  }

  export function buildNewFillFeature(position: WorldPoint, community: CommunityInfos): FillFeature {
    let color = 'none'
    if (community) {
      color = community.color;
    }
    return {
      type: "Feature",
      properties: {
        color: color,
        height: 0,
        base_height: 0,
        communityId: community.id
      },
      geometry: {
        coordinates: [
          position,
          [position[0], position[1] + TILES_OFFSET_LAT],
          [position[0] + TILES_OFFSET_LONG, position[1] + TILES_OFFSET_LAT],
          [position[0] + TILES_OFFSET_LONG, position[1]],
          position
        ],
        type: "LineString"
      },
      id: position
    }
  }

  export function makeCoordLegal(worldPoint: WorldPoint): WorldPoint
  {
    let resWorldPoint: WorldPoint = [-200, -200]

    let factorX = worldPoint[0] / TILES_OFFSET_LONG
    let roundedFactorX = Math.round(factorX)
    resWorldPoint[0] = roundedFactorX * TILES_OFFSET_LONG

    let factorY = worldPoint[1] / TILES_OFFSET_LAT
    let roundedFactorY = Math.round(factorY)
    resWorldPoint[1] = roundedFactorY * TILES_OFFSET_LAT

    return resWorldPoint;
  }

  export function worldPointToTileGeometry(position: WorldPoint): TileGeometry {
    return [
      position,
      [position[0], position[1] + TILES_OFFSET_LAT],
      [position[0] + TILES_OFFSET_LONG, position[1] + TILES_OFFSET_LAT],
      [position[0] + TILES_OFFSET_LONG, position[1]],
      position
    ]
  }

  export function calculateTileHeight(postDate: number, starsDonated: number, starsInvested: number): number
  {
    return lifetimeToTileHeight(calculateLifetimeInHours(postDate, starsDonated, starsInvested))
  }

  export function lifetimeToTileHeight(lifetime: number)
  {
    return 0.5 * (lifetime**1.3)
  }

  export function calculateLifetimeInMS(postDate: number, starsDonated: number, starsInvested: number): number
  {
    return environment.defaultLifetimeInMS - (Date.now() - postDate) + ((starsDonated + starsInvested) * environment.starValueInMS)
  }

  export function calculateLifetimeInHours(postDate: number, starsDonated: number, starsInvested: number): number
  {
    return calculateLifetimeInMS(postDate, starsDonated, starsInvested) / (3600 * 1000)
  }

  export function getTileBounds(tile: TileGeometry): [WorldPoint, WorldPoint]
  {
    return [tile[0], tile[2]];
  }

  export function getTilesBounds(tiles: WorldPoint[]): [WorldPoint, WorldPoint]
  {
    let lats = tiles.map(tile => tile[0]);
    let longs = tiles.map(tile => tile[1]);
    let minX = Math.min(...lats);
    let minY = Math.min(...longs);
    let maxX = Math.max(...lats);
    let maxY = Math.max(...longs);
    return [[minX, minY], [maxX, maxY]];
  }

  export function getCommunitiesBounds(tilesData: TileData[], communities: CommunityInfos[]): [WorldPoint, WorldPoint] {
    let tiles = tilesData.filter(tile => communities.find(c => c.id == tile.communityId) != null).map(tile => tile.position);
    return MapUtils.getTilesBounds(tiles);
  }

  export function getRandomCordinatesFromCommunity(tilesData: TileData[], communityId: string): WorldPoint {
    let tiles = tilesData.filter(tile => tile.communityId == communityId).map(tile => tile.position);
    let randIndex = Math.floor(Math.random() * tiles.length);
    return tiles[randIndex];
  }

  export function addOffset(point: WorldPoint): WorldPoint {
    return [point[0] + APPROXIMATE_CENTER_OFFSET, point[1] + APPROXIMATE_CENTER_OFFSET];
  }

  export function substractOffset(point: WorldPoint): WorldPoint {
    return [point[0] - APPROXIMATE_CENTER_OFFSET, point[1] - APPROXIMATE_CENTER_OFFSET];
  }
}