import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { CommunityService } from '../../../data/community.service';
import { CommunityInfos } from 'types/build';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-community-overlay',
  templateUrl: './community-overlay.component.html',
  styleUrls: ['./community-overlay.component.scss'],
})
export class CommunityOverlayComponent  implements OnInit {

  modalBreakpoints = [0, 0.5, 1];
  communityId?: string;
  communityInfos?: Observable<CommunityInfos>;
  isMobile?: boolean;

  constructor(private route: ActivatedRoute,
    private platform: Platform,
    private communityService: CommunityService
  ) { }

  ngOnInit()
  {
    if (!this.route.snapshot.paramMap.get('communityid')) return;

    this.communityId = this.route.snapshot.paramMap.get('communityid')!;
    console.log(this.communityId);

    this.isMobile = this.platform.is('mobile');

    this.communityInfos = this.communityService.getCommunityInfo(this.communityId)
  }
}
