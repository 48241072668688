import { Map } from 'maplibre-gl';

export class TileSelectAnimation {
    private _animation = 0;
    private _lineWidth;
    private _maxLineWidth = 5;
    private _minLineWidth = 2;

    private _speedFactor = 0.05;
    private _interpolationFactor = 0.5;

    private _goingUp = true;

    private _map: Map;

    public constructor(map: Map) {
        this._lineWidth = this._minLineWidth;
        this._map = map;
    }
    public get lineWidth(): number {
        return this._lineWidth;
    }
    public cancel() {
        cancelAnimationFrame(this._animation);
    }

    public animate() {
        let layer = this._map.getLayer('selected')!;

        if (this._lineWidth >= this._maxLineWidth) {
            this._goingUp = false;
        } else if (this._lineWidth <= this._minLineWidth) {
            this._goingUp = true;
        }
        this._goingUp ? this._lineWidth += this._speedFactor : this._lineWidth -= this._speedFactor;
        let interpolatedWidth = this.interpolateWidth(this._lineWidth);
        //@ts-ignore
        layer.paint._values["line-width"].value.value = interpolatedWidth;
        this._map.triggerRepaint();
        
        
        this._animation = requestAnimationFrame(() => this.animate());
    }

    private interpolateWidth(width: number): number {
        return Math.max(this._minLineWidth, Math.min(this._maxLineWidth, width * (this._interpolationFactor * width)));
    }
}