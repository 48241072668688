import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SponsorComponent } from '../sponsor/sponsor.component';

@Component({
  selector: 'app-sponsor-button',
  templateUrl: './sponsor-button.component.html',
  styleUrls: ['./sponsor-button.component.scss'],
})
export class SponsorButtonComponent implements OnInit {

  @Input() contentId: string | undefined
  @Input() isSponsoring: boolean | undefined
  @Input() disabled: boolean | undefined

  @ViewChild('sponsorModal') sponsorModal: SponsorComponent | undefined

  constructor() { }

  ngOnInit() {}

  sponsor()
  {
    if (this.isSponsoring && this.contentId)
    {
      this.sponsorModal?.Open(this.isSponsoring, false, this.contentId)
    }
  }

}
