import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { InteractionsService } from 'app/src/app/data/interactions.service';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, Observable, defaultIfEmpty, filter, map, tap, timer } from 'rxjs';

enum TileSelectionMode {
  None = 0,
  Empty = 1,
  Occupied = 2,
  Overlie = 3,
}

@Component({
  selector: 'app-map-overlay',
  templateUrl: './map-overlay.component.html',
  styleUrls: ['./map-overlay.component.scss'],
})
export class MapOverlayComponent  implements OnInit {
  tileSelectionMode = TileSelectionMode.None;
  timer$: Observable<number | null> = this.interactions.timer$;
  tileAvailable$: Observable<boolean> = this.interactions.tileAvailable$;
  constructor(private interactions: InteractionsService) { }

  ngOnInit() {
  }

  putTile() {
    this.interactions.sendSignal<boolean>(this.interactions.addTileButtonClicked, true);
  }

  createCommunity()
  {
    this.interactions.sendSignal<boolean>(this.interactions.createCommunityButtonClicked, true);
  }

  viewTile() {
    this.interactions.sendSignal<boolean>(this.interactions.viewTileButtonClicked, true);
  }
}
