import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { ContentGroup, SwipeDirection } from '@overlie/types';
import { ReplaySubject } from 'rxjs';
import { SwiperContainer } from 'swiper/element';

@Component({
  selector: 'app-swipe-slide',
  templateUrl: './swipe-slide.component.html',
  styleUrls: ['./swipe-slide.component.scss'],
})
export class SwipeSlideComponent implements OnInit, AfterViewInit {
  vcr = inject(ViewContainerRef);

  @Input({ required: true }) contents!: ContentGroup;

  @ViewChild('horizontal') horizontalSwiper!: ElementRef<SwiperContainer>;
  @ViewChild('vertical') verticalSwiper!: ElementRef<SwiperContainer>;

  @Output() swipeDirection = new ReplaySubject<SwipeDirection>();

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.horizontalSwiper.nativeElement.swiper.slidePrev();
    } else if (event.key === 'ArrowRight') {
      this.horizontalSwiper.nativeElement.swiper.slideNext();
    } else if (event.key === 'ArrowUp') {
      this.verticalSwiper.nativeElement.swiper.slidePrev();
    } else if (event.key === 'ArrowDown') {
      this.verticalSwiper.nativeElement.swiper.slideNext();
    }
  }

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.horizontalSwiper.nativeElement.addEventListener('swiperslideprevtransitionend', () => {
      this.swipeDirection.next('left');
    });
    this.horizontalSwiper.nativeElement.addEventListener('swiperslidenexttransitionend', () => {
      this.swipeDirection.next('right');
    });
    this.verticalSwiper.nativeElement.addEventListener('swiperslideprevtransitionend', (event: Event) => {
      event.stopPropagation();
      this.swipeDirection.next('top');
    });
    this.verticalSwiper.nativeElement.addEventListener('swiperslidenexttransitionend', (event: Event) => {
      event.stopPropagation();
      this.swipeDirection.next('bottom');
    });
  }

  onButtonClicked(event: { direction: SwipeDirection }) {
    switch (event.direction) {
      case 'left':
        this.horizontalSwiper.nativeElement.swiper.slidePrev();
        break;
      case 'right':
        this.horizontalSwiper.nativeElement.swiper.slideNext();
        break;
      case 'top':
        this.verticalSwiper.nativeElement.swiper.slidePrev();
        break;
      case 'bottom':
        this.verticalSwiper.nativeElement.swiper.slideNext();
        break;
    }
  }
}
