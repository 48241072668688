import { Component, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type Option = {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
};

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent implements OnInit {

  constructor() { }

  @Input() options: Option[] = [];
  @Input() disabled: boolean = false;
  _selected: BehaviorSubject<Option> = new BehaviorSubject<Option>(this.options[0]);
  @Output() selected: Observable<Option> = this._selected?.asObservable();

  ngOnInit() {
    for (let option of this.options) {
      if (option.selected) {
        this._selected?.next(option);
        break;
      }
    }
  }

  select(option: Option) {
    this.options.forEach(o => o.selected = false);
    option.selected = true;
    this._selected?.next(option);
  }
}
