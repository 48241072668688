import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MapService } from '../../data/map.service';
import { environment } from 'app/src/environments/environment';
import { AlertService } from '../../utils/alert.service';
import { Observable } from 'rxjs';
import { ConnectionInfos, ConnectionStatus, GoogleOAuthInfos } from '@overlie/types'
import { HttpClient } from '@angular/common/http';
import { GenericOAuth2 } from "@capacitor-community/generic-oauth2";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {

  constructor(private router: Router, private mapService: MapService, private alert: AlertService, private httpClient: HttpClient) { }

  ngOnInit() { }

  async loginWithGoogle() {
    let width = 500;
    let height = 600;

    try {
      let authData = await GenericOAuth2.authenticate({
        authorizationBaseUrl: "https://accounts.google.com/o/oauth2/auth",
        accessTokenEndpoint: "https://www.googleapis.com/oauth2/v4/token",
        scope: "email profile",
        resourceUrl: "https://www.googleapis.com/userinfo/v2/me",
        web: {
          appId: environment.oauthAppId.google.web,
          responseType: "token",
          accessTokenEndpoint: "",
          redirectUrl: `${environment.appUrl}/signin`,
          windowOptions: `height=${height},left=${(screen.width) / 2},top=${(screen.height - height) / 2}`
        },
        android: {
          appId: environment.oauthAppId.google.android,
          responseType: "code",
          redirectUrl: "net.overlie.twa:/signin"
        },
        ios: {
          appId: environment.oauthAppId.google.ios,
          responseType: "code",
          redirectUrl: "net.overlie.twa:/signin"
        }
      });
      let connectionInfos: GoogleOAuthInfos = {
        id: authData.id,
        token: authData.access_token,
        email: authData.email
      }

      this.sendConnectionInfos(connectionInfos).subscribe(() => {
        this.mapService.getMapData().subscribe();
        this.alert.success('Success', 'Successfully logged in');
        this.router.navigate(['/']);
      });
    }
    catch (error) {
      console.error("Google OAuth rejected", error);
    }
  }

  private sendConnectionInfos(infos: GoogleOAuthInfos): Observable<ConnectionInfos> {
    return this.httpClient.post<ConnectionInfos>(`${environment.apiUrl}/auth/google/token`, infos, environment.httpOptions)
  }
}
