<div class="content-type-select">
  @for (option of options; track $index) {
  <ion-chip class="chip"
            (click)="option.disabled ? false : select(option)"
            [ngClass]="[option.disabled ? 'disabled':'', option.selected ? 'selected': '']" 
            [outline]="true">
    @if (option.selected){
      <ion-icon color="#fff" name="checkmark-outline"></ion-icon>
    }
    {{option.label}}
  </ion-chip>
  }
</div>