import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  
  constructor() {}

  /**
   * Set a value in local storage
   * @param key Key of the item to store
   * @param value Value of the item to store
   */
  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  /**
   * Get a value from local storage
   * @param key Key of the item to retrieve
   * @returns The value of the item or null if the item does not exist
   */
  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  /**
   * Remove a value from local storage
   * @param key Key of the item to remove
   */
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Clear all items from local storage
   */
  clear(): void {
    localStorage.clear();
  }
}
