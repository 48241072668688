<div>
  <ion-segment value="polls" (ionChange)="changePage($event)">
    <ion-segment-button value="polls">
      <p>Polls</p>
    </ion-segment-button>
    <ion-segment-button value="users">
      <p>Users</p>
    </ion-segment-button>
  </ion-segment>
  <div>
    @if (currentPage == 'polls')
    {
      <app-polls-list [communityId]="communityId"></app-polls-list>
    }
    @else if (currentPage == 'users')
    {
      <app-users-list [communityId]="communityId"></app-users-list>
    }
  </div>
</div>

