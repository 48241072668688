export interface IUserSchema {
    _id: string,
    _username: string,
    _normalizedEmail: string,
    _communities: string[],
    _bookmarks: string[],
    _tileDelay: number,
    _nextTileDate: number,
    _isAdmin: boolean,
    _isSubscribed: boolean,
    _quitCommunityTickets: number,
    _googleId: string | undefined,
    _verificationToken: string | undefined,
    _salt: string | undefined,
    _hash: string | undefined,
}

export interface UserInfos extends UserMinimalInfos {
    isAdmin: boolean,
    normalizedEmail: string,
    hash?: string,
    salt?: string,
    googleId?: string,
    verificationToken?: string,
}

export type UserMinimalInfos = {
    id: string,
    username: string,
    communities: string[],
    bookmarks: string[],
    tileDelay: number,
    nextTileDate: number,
    isSubscribed: boolean,
    quitCommunityTickets: number
};