<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu #menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="tabs">
          <ion-menu-toggle auto-hide="false">
            <ion-item routerDirection="root" routerLink="/profile" lines="none" detail="false"
              routerLinkActive="selected">
              <ion-avatar>
                <img alt="Avatar" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
              </ion-avatar>
              <span>
                <ion-list-header>{{ username$ | async }}</ion-list-header>
                <ion-note>{{ (stars$ | async) || 0 }} pts</ion-note>
              </span>
            </ion-item>
          </ion-menu-toggle>
          @for (tab of appPages; track $index) {
          <ion-menu-toggle auto-hide="false">
            <ion-item routerDirection="root" [routerLink]="[tab.url]" lines="none" detail="false"
              routerLinkActive="selected">
              <ion-icon aria-hidden="true" slot="start" [ios]="tab.icon + '-outline'"></ion-icon>
              <ion-label>{{ tab.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          }
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-content [fullscreen]="fullscreen" id="main-content">

      @if (!fullscreen) {
      <ion-header [translucent]="false">
        <ion-toolbar>
          <ion-menu-button slot="start">
            <ion-icon name="menu"></ion-icon>
          </ion-menu-button>
          <ion-title>{{ title$ | async }}</ion-title>
        </ion-toolbar>
      </ion-header>
      }

      @if (showFab){
      <ion-fab-button color="secondary" (click)="this.menu.toggle()">
        <ion-icon name="menu" color="primary"></ion-icon>
      </ion-fab-button>
      }

      <ion-router-outlet [ngStyle]="{'transition': 'background 1s ease-in-out','background': backgroundColor}"></ion-router-outlet>
    </ion-content>
  </ion-split-pane>
</ion-app>