import { WorldPoint } from "./MapData";

export enum MediaType {
    Image = 'image',
    Video = 'video'
}

export enum ContentType {
    Text = 'text',
    Image = 'image',
    Video = 'video',
    GIF = 'GIF'
}

export enum AcceptedMimeTypes { 'image/jpeg', 'image/png', 'video/mp4' };
export type ContentExtension = '.jpg' | '.jfif' | '.png' | '.mp4';

/**
 * Database content schema
 */
export interface IContentSchema
{
    _id: string,
    _contentId: string
    _location: {
        type: 'Point',
        coordinates: [number, number]
    },
    _path: string,
    _userId: string,
    _communityId: string,
    _views: number,
    _extension: ContentExtension,
    _postDate: number,
    _nbSponsorisations: number,
    _overbucksDonated: number,
    _overbucksInvested: number,
    _type: ContentType,
    _text: string
    _gifUrl: string | undefined;
}

/**
 * Public content metadata
 */
export interface ContentMetadata {
    id: string | undefined;
    contentId: string | undefined;
    userId: string | undefined;
    communityId: string | undefined;
    views: number | undefined;
    postDate: number | undefined;
    overbucksDonated: number | undefined;
    overbucksInvested: number | undefined;
    type: ContentType | undefined;
    gifUrl: string | undefined;
    text: string | undefined;
}

/**
 * Content metadata containing also nearby contents
 */
export interface ContentMetadataWithNearby extends ContentMetadata {
    nearbyContents: Record<SwipeDirection, ContentMetadata | null>;
}

/**
 * Personal content metadata
 */
export interface PersonalContentMetadata extends ContentMetadata {
    nbSponsorisations: number;
}

export type ContentPosition = 'middle' | 'top' | 'bottom' | 'left' | 'right';
export type SwipeDirection = 'left' | 'right' | 'top' | 'bottom';

export type ContentGroup = Record<ContentPosition, ContentMetadata | null>;

export interface Media { 
    mediaType: MediaType;
    type: string; 
    blob: Blob;
    src: string;
}

export interface ContentBasePOST {
    communityId: string;
    text: string;
    coordinates: WorldPoint;
    sponsorisationAmount?: number;
}

export interface ContentTextPOST extends ContentBasePOST {
    contentType: 'text';
}

export interface ContentImagePOST extends ContentBasePOST {
    contentType: 'image';
    media: Blob | File;
}

export interface ContentVideoPOST extends ContentBasePOST {
    contentType: 'video';
    media: Blob;
}

export interface ContentGifPOST extends ContentBasePOST {
    contentType: 'GIF';
    gifUrl: string;
}

export type IContentPOST = ContentTextPOST | ContentImagePOST | ContentVideoPOST | ContentGifPOST; 