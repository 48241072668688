import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, of, take } from 'rxjs';
import { ContentType, ContentMetadata, PersonalContentMetadata, IContentPOST, ContentMetadataWithNearby, SwipeDirection } from '@overlie/types';
import { environment } from 'app/src/environments/environment';
import { UserService } from './user.service';
import { OverlieHttpService } from './overliehttp.service';

@Injectable({
  providedIn: 'root'
})
export class ContentsService {

  contentSponsorised: Subject<{id: string, amount: number}> = new Subject<{id: string, amount: number}>();
  swipeDirection: SwipeDirection | null = null;
  previousContent: ContentMetadata | null = null;
  currentContent: ContentMetadata | null = null;
  
  constructor(private HttpClient: OverlieHttpService,
    private UserService: UserService) { }

  getContentsForUser(userId: string): Observable<PersonalContentMetadata[]> {
    return this.HttpClient.get<PersonalContentMetadata[]>(`${environment.apiUrl}/content?userId=${userId}`)
  }

  getContent(contentId: string): Observable<ContentMetadata> {
    return this.HttpClient.get<ContentMetadata>(`${environment.apiUrl}/content?coordinates=${contentId}`)
      .pipe(
        catchError((e) => {
          console.error(e)
          return new Observable<ContentMetadata>();
        })) as Observable<ContentMetadata>;
  }

  getContentWithNearby(contentId: string): Observable<ContentMetadataWithNearby | undefined> {
    return this.HttpClient.get<ContentMetadataWithNearby>(`${environment.apiUrl}/content?coordinates=${contentId}&nearby=true`)
      .pipe(
        catchError(() => {
          return of(undefined);
        })) as Observable<ContentMetadataWithNearby>;
  }

  getContentNearbyContent(contentId: string): Observable<ContentMetadata[]> {
    return this.HttpClient.get<ContentMetadata[]>(`${environment.apiUrl}/content/nearby?coordinates=${contentId}`)
  }

  getContentPayloadURL(contentId: string): string {
    return `${environment.apiUrl}/contentpayload?coordinates=${contentId}`
  }

  sponsorContent(contentId: string, amount: number, isSponso: boolean) {
    this.UserService.userProfile.pipe(take(1)).subscribe(profile => {
      this.HttpClient.put(`${environment.apiUrl}/content/sponsor`, { contentId: contentId, userId: profile.id, amount: amount, isSponso: isSponso })
        .subscribe(() => this.contentSponsorised.next({id: contentId, amount: amount}));
    });
  }

  postContent(content: IContentPOST): Observable<ContentMetadata | null> {
    let formData = new FormData();
    formData.append('text', encodeURI(content.text));
    formData.append('communityId', content.communityId);
    formData.append('type', content.contentType);
    if (content.sponsorisationAmount) {
      formData.append('sponsorisationAmount', content.sponsorisationAmount?.toString());
    }
    if (content.contentType === 'GIF')
      formData.append('gifUrl', content.gifUrl);
    if (content.contentType === ContentType.Image || content.contentType === ContentType.Video) {
      formData.append('contentfile', content.media);
    }

    return this.HttpClient.post<ContentMetadata>(`${environment.apiUrl}/contentpayload?coordinates=${content.coordinates.join(',')}`,
      formData);
  }
}
