import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  public title!: string;
  private activatedRoute = inject(ActivatedRoute);
  public showFab = false;
  public fullscreen = false;
  constructor(
    public platform: Platform) {}

  @Output() toggleMenu = new EventEmitter<boolean>();
  ngOnInit() {
    this.title = this.activatedRoute.snapshot.title as string;
    this.fullscreen = this.title === 'Explore';
    this.showFab = this.fullscreen && !this.platform.is('desktop');
  }
}
