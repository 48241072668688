export interface IBadgeSchema
{
    _id: number,
    _name: string,
    _description: string,
    _message: string,
}

export class Badge
{
    _id: number;
    name: string;
    description: string;
    message: string;

    public constructor(id: number, name: string, description: string, message: string)
    {
        this._id = id;
        this.name = name;
        this.description = description;
        this.message = message;
    }
}