<ion-modal #modal [initialBreakpoint]="modalBreakpoints[1]" [breakpoints]="modalBreakpoints">
  <ng-template>
    <div id="layout">
      <h2>Create community</h2>
      <div class="flex-container">
        <div class="name-part">
          <ion-input #communityName class="input" maxlength="30" placeholder="Enter a name" [counter]="true" ></ion-input>
        </div>
        <div #color class="color-part">
          <div class="color-picker-container">
            <input #colorPicker id="color-picker"  type="color" [value]="colorValue" (input)="updateColorValue()">
            <p id="color-value" >{{colorValue | uppercase}}</p>
          </div>
        </div>
        <div class="image-part">
          <ion-avatar (click)="uploadImage()" class="community-image">
            @if (uploadedImage) {
              <ion-button class="remove-image" (click)="removeMedia($event)">
                <ion-icon name="close-outline"></ion-icon>
              </ion-button>
              
              <img alt="Community image" [src]="uploadedImage.src" />
            } @else {
              <img class="image-placeholder" alt="Image placeholder" src="/assets/icon/image-outline.svg" />
            }
          </ion-avatar>
          <div class="image-info">Choose a picture (maximum 2MB)</div>
        </div>
      </div>
      <div #tagSelection id="tag-selection">
        <ion-input #tagInput class="input" (keyup.enter)="confirmTag()" (keyup.space)="confirmTag()" (ionInput)="onTagInput($event)" [disabled]="tagsLimit" maxlength="15" placeholder="Choose tags representing the community (max 5)" type="text" [counter]="true"></ion-input>
        <button id="confirm-tag" (click)="confirmTag()" [disabled]="tagsLimit">
          <ion-icon color="primary" name="Add"></ion-icon>
        </button>
      </div>
      <div id="tag-list">
        @for(tag of tags; track $index)
        {
          <app-tag [name]="tag" [canDelete]="true" (deletedTag)="removeTag(tag)"></app-tag>
        }
      </div>
      <p>Describe your community</p>
      <ion-textarea #descInput id="desc" class="input" maxlength="150" placeholder="Add a description" [autoGrow]="true" [counter]="true"></ion-textarea>
    </div>
      <div id="confirm-cancel">
        <app-action-button class="action-button" type="tertiary" (click)="cancelCreation()" text="Cancel"></app-action-button>
        <app-action-button class="action-button" type="primary" (click)="confirmCreation()" text="Confirm"></app-action-button>
      </div>
  </ng-template>
</ion-modal>