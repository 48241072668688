import { ApplicationRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, Injector, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, Subject, firstValueFrom, map, tap } from 'rxjs';
import { CommunityInfos, FeatureCollection, LineStringFeatureCollection, LineStringFeature, SeaRouteFeature, SeaRouteObject, SwipeDirection, TagLink, TileData, TileGeometry, WorldPoint } from '@overlie/types';
import { environment } from 'app/src/environments/environment';
import { CommunityService } from './community.service';
import { Feature, LngLat, LngLatLike, Map, Marker, Popup, Source } from 'maplibre-gl';
import { CommunityPopupComponent } from '../components/communities/community-popup/community-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OverlieHttpService } from './overliehttp.service';
import { MapUtils } from '../utils/MapUtils';
import { along } from '@turf/turf';

type MapData = {
  communities: CommunityInfos[];
  borders: {
    borders: WorldPoint[][];
    types: string[];
  }
  tiles: TileData[];
  seaRoutes: SeaRouteObject[];
}

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(
    private http: OverlieHttpService, 
    private communityService: CommunityService, 
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private zone: NgZone) { }

  mapData?: BehaviorSubject<MapData | undefined> = new BehaviorSubject<MapData | undefined>(undefined);
  focusedCoordinates?: BehaviorSubject<[number, number] | undefined> = new BehaviorSubject<[number, number] | undefined>(undefined);
  focusedArea?: BehaviorSubject<[WorldPoint, WorldPoint] | undefined> = new BehaviorSubject<[WorldPoint, WorldPoint] | undefined>(undefined);
  selectedTile?: BehaviorSubject<TileGeometry | undefined> = new BehaviorSubject<TileGeometry | undefined>(undefined);
  popupRefs: ComponentRef<CommunityPopupComponent>[] = [];
  communityPopups: Marker[] = [];
  endFocused: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);

  tagLinksFeatures: LineStringFeatureCollection = {
    type: "FeatureCollection",
    features: []
  }

  selectedTagLink: BehaviorSubject<TagLink | undefined> = new BehaviorSubject<TagLink | undefined>(undefined);

  public getMapData(): Observable<MapData> {
    return this.http.get<MapData>(`${environment.apiUrl}/infos/map`).pipe(
      tap((data: MapData) => {
        if (data) {
          console.log(data.communities);
          this.mapData?.next(data);
        }
      })
    );
  }

  public addTileToMap(tile: TileData) {
    if (!this.mapData || !this.mapData.value || !this.mapData.value.tiles)
      return;
    this.mapData.value.tiles.push(tile);
    this.mapData.next(this.mapData.value);
  }

  public goToCoordinates(coordinates: WorldPoint) {
    this.focusedCoordinates!.next(coordinates)
  }

  public focusArea(area: [WorldPoint, WorldPoint])
  {
    this.focusedArea!.next(area)
  }

  public getCoordinatesFromId(id: string): WorldPoint {
    let coords = id.split(',');
    return [parseFloat(coords[0]), parseFloat(coords[1])];
  }

  public getNearbyIds(id: string): Record<SwipeDirection, string> {
    let coords = this.getCoordinatesFromId(id);
    let top = [coords[0], coords[1] + MapUtils.TILES_OFFSET_LAT];
    let bottom = [coords[0], coords[1] - MapUtils.TILES_OFFSET_LAT];
    let right = [coords[0] + MapUtils.TILES_OFFSET_LONG, coords[1]];
    let left = [coords[0] - MapUtils.TILES_OFFSET_LONG, coords[1]];
    return {
      'top': top.join(','),
      'bottom': bottom.join(','),
      'right': right.join(','),
      'left': left.join(',')
    };
  }

  public async clearCommunityPopups() {
    if (!this.communityPopups) return;
    for (let popup of this.communityPopups) {
      popup.remove();
    }
    for (let popupRef of this.popupRefs) {
      popupRef.destroy();
    }
    this.communityPopups = [];
    this.popupRefs = [];
  }

  public async displayCommunityPopup(communityId: string, lngLat: LngLatLike, map: Map, unique: boolean) {
    if (unique) {
      this.clearCommunityPopups();
    }

    this.zone.runOutsideAngular(() => {
      const popupElement = document.createElement('div');

      this.communityPopups.push(new Marker({ element: popupElement, anchor: 'bottom' })
        .setOffset([0, -20])
        .setLngLat(lngLat)
        .addTo(map));
      this.loadPopup(popupElement, communityId);
    });
  }

  private loadPopup(container: HTMLElement, communityId: string) {
    const factory = this.resolver.resolveComponentFactory(CommunityPopupComponent);
    let popupRef = factory.create(this.injector)
    this.popupRefs.push(popupRef);

    this.appRef.attachView(popupRef.hostView);
    container.appendChild((popupRef.hostView as any).rootNodes[0]);

    let communityInfos = this.communityService.communities.value.get(communityId);
    if (communityInfos != null) {
      popupRef.instance.communitiesInfos = communityInfos;
    }
  }

  public selectNewTile(wp: WorldPoint) {
    this.selectedTile?.next(MapUtils.worldPointToTileGeometry(wp));
  }

  private initTagLinks(mapRef: Map) {
    mapRef.addSource('tag-links', {
      'type': 'geojson',
      lineMetrics: true,
      'data': this.tagLinksFeatures
    });

    mapRef.addLayer({
      'id': 'tag-links',
      'type': 'line',
      'source': 'tag-links',
      'paint': {
        // "line-dasharray": [5, 2],
        "line-color": ['get', 'color'],
        "line-width": 2,
        // 'line-gradient': [
        //   'interpolate',
        //   ['linear'],
        //   ['line-progress'],
        //   0,
        //   '#2400FF',
        //   0.33,
        //   '#B100C1',
        //   0.66,
        //   '#C40039',
        //   1,
        //   '#C37500'
        // ]
      },
      'layout': {
      }
    });
    mapRef.moveLayer('tag-links', 'communities-names');
  }

  public async clearTagLinks(mapRef?: Map) {
    if (!mapRef) return;
    let tagLinks = mapRef.getSource('tag-links');
    if (!tagLinks) return;
    this.tagLinksFeatures.features = [];
    //@ts-ignore
    tagLinks.setData(this.tagLinksFeatures);
  }

  public async traceTagLink(point1: WorldPoint, point2: WorldPoint, mapRef: Map, color?: string) {
    let tagLinks = mapRef.getSource('tag-links');
    if (!tagLinks) {
      this.initTagLinks(mapRef);
      tagLinks = mapRef.getSource('tag-links')!;
    }
    this.tagLinksFeatures.features.push({
      type: "Feature",
      id: Math.floor(Math.random() * 10000).toString(),
      properties:
      {
        color: color || '#ffffff',
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [point1[0] + MapUtils.APPROXIMATE_CENTER_OFFSET, point1[1] + MapUtils.APPROXIMATE_CENTER_OFFSET],
          [point2[0] + MapUtils.APPROXIMATE_CENTER_OFFSET, point2[1] + MapUtils.APPROXIMATE_CENTER_OFFSET],
        ]
      }
    });
    //@ts-ignore
    tagLinks.setData(this.tagLinksFeatures);
  }

  /**
   * Speed factor for the animation. Keep it between 0 and 1.
   */
  private speedFactor: number = 0.3;
  private distance: number = 0;
  private terminatedLines: Set<string> = new Set();

  animateLines(source: Source, color?: string) {
    if (this.terminatedLines.size >= this.tagLinksFeatures.features.length) {
      this.distance = 0;
      this.terminatedLines = new Set();
    } else {
      let newFeatures: LineStringFeatureCollection = { features: [], type: "FeatureCollection" };
      for (let feat of this.tagLinksFeatures.features) {
        let point = along(feat.geometry, this.distance);
        if (point.geometry.coordinates == feat.geometry.coordinates[1]) {
          this.terminatedLines.add(feat.id);
        }
        let newFeature: LineStringFeature = {
          type: "Feature",
          id: `${point.geometry.coordinates[0]}-${point.geometry.coordinates[1]}`,
          properties:
          {
            color: color || '#ffffff',
          },
          geometry: {
            type: 'LineString',
            coordinates: [
              feat.geometry.coordinates[0],
              point.geometry.coordinates as [number, number]
            ]
          }
        }
        newFeatures.features.push(newFeature);
      }
      this.distance = this.distance + this.speedFactor;
      //@ts-ignore
      source.setData(newFeatures);
      requestAnimationFrame(() => this.animateLines(source, color));
    }
  }
} 