<div id="main-container" [ngStyle]="{ 'border-color': poll?.color, 'padding-bottom': expanded ? '3em' : '0px' }" (click)="expandCollapse()">
  <p>{{ poll!.title }}</p>
  @if (expanded)
  {
    @for(choice of sortedChoices; track $index)
    {
      <app-poll-choice
        #choice
        [pollId]="poll?._id"
        [pollChoice]="choice"
        [color]="poll?.color" 
        [totalVotes]="poll?.nbVoters"
        [isWinning]="0 == $index && choice.score > sortedChoices![1].score" 
        [displayResults]="(answered | async)! || voteDelayExpired"
        [selected]="selectedIndex == choice.index"></app-poll-choice>
    }
  }
</div>
