import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageComponent } from './page/page.component';
import { MapViewComponent } from './views/map-view/map-view.component';
import { MapOverlayComponent } from './views/map-view/map-overlay/map-overlay.component';
import { SigninComponent } from './views/signin/signin.component';
import { AuthGuard, UnauthGuard } from './guards/auth.guard';
import { SettingsComponent } from './views/settings/settings.component';
import { PostsComponent } from './views/map-view/posts/posts.component';
import { CommunitiesComponent } from './views/map-view/communities/communities.component';
import { ContentViewComponent } from './views/content-view/content-view.component';
import { SwipeOverlayComponent } from './views/content-view/swipe-overlay/swipe-overlay.component';
import { CommunityOverlayComponent } from './views/map-view/community-overlay/community-overlay.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/explore',
    pathMatch: 'full'
  },
  {
    path: 'signin',
    title: 'Sign in',
    component: SigninComponent,
    canActivate: [ UnauthGuard ]
  },
  {
    path: 'signup',
    title: 'Sign up',
    component: SigninComponent,
    canActivate: [ UnauthGuard ]
  },
  {
    path: 'content',
    title: 'Content',
    component: ContentViewComponent,
    data: {
      fullscreen: true,
    }
  },
  {
    path: 'communities',
    redirectTo: '/explore/communities',
  },
  {
    path: 'explore',
    title: 'Explore',
    data: { fullscreen: true },
    component: MapViewComponent,
    canActivate: [ AuthGuard ],
    children: [
      {
        path: '',
        component: MapOverlayComponent
      },
      {
        path: 'communities',
        component: CommunitiesComponent
      },
      {
        path: 'community/:communityid',
        component: CommunityOverlayComponent
      },
      {
        path: 'posts',
        component: PostsComponent
      }
    ]
  },
  {
    path: 'posts',
    title: 'My posts',
    component: PageComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'communities',
    title: 'Communities',
    component: PageComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'messages',
    title: 'Messages',
    component: PageComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'settings',
    title: 'Settings',
    component: SettingsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'profile',
    title: 'Profile',
    component: PageComponent,
    canActivate: [ AuthGuard ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
