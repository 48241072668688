import { Component, OnInit } from '@angular/core';
import { CommunityService } from 'app/src/app/data/community.service';
import { environment } from 'app/src/environments/environment';
import { combineLatest, filter, map, Observable, Subject } from 'rxjs';
import { CommunityInfos } from 'types/build';

@Component({
  selector: 'app-community-list',
  templateUrl: './community-list.component.html',
  styleUrls: ['./community-list.component.scss'],
})
export class CommunityListComponent implements OnInit {

  joinedCommunities?: Observable<CommunityInfos[]>;
  bookmarkedCommunities?: Observable<CommunityInfos[]>;

  joinLimit?: number;

  expandJoined?: boolean;
  expandBookmarks?: boolean;

  constructor(private communityService: CommunityService) { }

  ngOnInit()
  {
    this.joinLimit = environment.maxJoin;
    this.expandBookmarks = true;
    this.expandJoined = true;

    this.joinedCommunities = combineLatest([this.communityService.communities, this.communityService.myCommunities])
      .pipe(map(([communities, myCommunities]) =>
        {
          return Array.from(communities.values()).filter(v => myCommunities.find(id => id == v.id) != null)
        }));

    this.bookmarkedCommunities = combineLatest([this.communityService.communities, this.communityService.myBookmarks])
      .pipe(map(([communities, myBookmarks]) =>
        {
          return Array.from(communities.values()).filter(v => myBookmarks.find(id => id == v.id) != null)
        }));
  }

  HideShowJoined()
  {
    this.expandJoined = !this.expandJoined;
  }

  HideShowBookmarked()
  {
    this.expandBookmarks = !this.expandBookmarks;
  }
}
