import { Component, input, Input, OnInit, ViewChild } from '@angular/core';
import { CommunityInfos, TagLink, UserMinimalInfos } from '@overlie/types';
import { CommunityService } from '../../data/community.service';
import { first, forkJoin, merge, Observable, tap } from 'rxjs';
import { MapService } from '../../data/map.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from '../../utils/file.service';
import { UserService } from '../../data/user.service';
import { AlertService } from '../../utils/alert.service';
import { IonInput, IonTextarea } from '@ionic/angular';
import { environment } from 'app/src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  @Input({ required: true }) type!: 'user' | 'community';
  @Input() community?: CommunityInfos;
  @Input() user?: UserMinimalInfos;
  @Input() canEdit: boolean = false;
  

  @ViewChild('description') descriptionInput!: IonTextarea;
  @ViewChild('tagInput') tagInput!: IonInput;

  editDescriptionMode: boolean = false;
  editTagsMode: boolean = false;
  savedTags: string[] = [];
  tagsLimit = false;
  selectedTag: Observable<TagLink | undefined > = this.mapService.selectedTagLink;

  userCommunities?: Observable<CommunityInfos[]>
  communityRoles: Record<string, 'Member' | 'Moderator' | 'Administrator' | 'Creator'> = {};

  constructor(
    private mapService: MapService, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fileService: FileService,
    private communityService: CommunityService,
    private userService: UserService,
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.savedTags = this.community?.tags.slice() ?? []
    if (this.user) {
      this.userCommunities = forkJoin(this.user.communities.map(communityId => this.communityService.getCommunityInfo(communityId).pipe(tap(c => {
        this.communityRoles[c.id] = this.communityService.isCreator(c, this.user!.id) ? 'Creator' :
                                    this.communityService.isAdmin(c, this.user!.id) ? 'Administrator' :
                                    this.communityService.isModerator(c, this.user!.id) ? 'Moderator' : 'Member';
      }))));
    }
  }

  showRelatedCommunities(tag: string, event: MouseEvent) {
    event.stopPropagation();
    if (this.editTagsMode) return;
    this.selectedTag?.pipe(
      first(),
      tap(selectedTagValue => {
        if (selectedTagValue && selectedTagValue.tag == tag) {
          this.mapService.clearTagLinks();
          this.mapService.selectedTagLink.next(undefined);
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { tag: null, origin: null },
            preserveFragment: true,
            queryParamsHandling: 'merge'
          });
        } else {
          if (!this.community) return;
          this.mapService.selectedTagLink.next({ originCommunityId: this.community.id, tag });
          this.router.navigate([], { 
            relativeTo: this.activatedRoute,
            queryParams: { tag, origin: this.community.id },
            preserveFragment: true,
            queryParamsHandling: 'merge'
          });
        }
      })
    ).subscribe();  
  }

  seeCommunity(communityId: string) { 
    this.router.navigate(['/explore/community', communityId]);
  }

  async editAvatar() {
    let media = await this.fileService.uploadMedia(['image/*']);
    if (!media) return;
    if (media.blob.size > 50000000) {
      this.alert.error("Oups...", "Image is too large (max 5MB)");
      return;
    }
    let image = await this.fileService.blobToBase64(media.blob);
    if (!image) return;
    if (this.community) {
      this.communityService.editCommunity(this.community.id, { image }).pipe(first()).subscribe((c) => {
        if (!c) return;
        this.community = c;
        this.alert.success("Success", "Community avatar updated");
      });
    } else {
      this.userService.editUserProfile({ avatar: image }).pipe(first()).subscribe((u) => {
        if (!u) return;
        this.user = u;
        this.alert.success("Success", "User avatar updated");
      });
    }
  }

  enterEditDescriptionMode() {
    if (!this.canEdit) return;
    this.editDescriptionMode = true
  }

  cancelEditDescription() {
    this.editDescriptionMode = false;
    this.descriptionInput.value = this.community?.description;
  }

  async editDescription() {
    this.editDescriptionMode = false;
    if (this.descriptionInput.value == this.community?.description || this.descriptionInput.value == undefined) {
      return;
    }
    if (this.community) {
      this.communityService.editCommunity(this.community.id, { description: this.descriptionInput.value }).pipe(first()).subscribe((c) => {
        if (!c) return;
        this.community = c;
        this.alert.success("Success", "Community description updated");
      });
    }
  }

  enterEditTagsMode() {
    if (!this.canEdit || !this.community || this.editTagsMode) return;
    this.editTagsMode = true;
    this.savedTags = this.community.tags.slice();
    this.tagsLimit = this.community!.tags.length == environment.maxTags;
    this.mapService.selectedTagLink.next(undefined);
  }

  cancelEditTags() {
    if (!this.community) return;
    this.editTagsMode = false;
    this.community.tags = this.savedTags;
    this.tagsLimit = this.community!.tags.length == environment.maxTags;
  }

  deleteTag(tag: string) {
    if (!this.community) return;
    this.community.tags = this.community.tags.filter(t => t != tag);
    this.tagsLimit = this.community.tags.length == environment.maxTags;
  }

  addTag() {
    if (!this.tagInput || !this.community) return;
    if (this.tagInput.value == "") return;

    if (this.community.tags?.length == environment.maxTags) return;
    if (this.community.tags?.find(tag => tag == this.tagInput?.value) != null) return;

    this.community.tags?.push(this.tagInput.value as string);
    this.tagInput.value = "";
    if (this.community.tags.length == environment.maxTags) this.tagsLimit = true;
  }
  
  onTagInput(event: any) {
    const value = event.target!.value;

    let filteredValue = value.replace(/[^a-zA-Z0-9]+/g, '');
    if (this.tagsLimit) filteredValue = '';
    event.target.value = filteredValue;
  }

  async editTags() {
    this.editTagsMode = false;
    if (this.community?.tags == this.savedTags) return;
    if (this.community) {
      this.communityService.editCommunity(this.community.id, { tags: this.community.tags }).pipe(first()).subscribe((c) => {
        if (!c) return;
        this.community = c;
        this.alert.success("Success", "Community tags updated");
      });
    }
  }
}
