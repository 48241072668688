import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MapService } from '../../../data/map.service';
import { BehaviorSubject, Observable, Subscription, first, map, of, switchMap, tap } from 'rxjs';
import { TileData, WorldPoint, CommunityInfos, TagLink } from '@overlie/types';
import { InteractionsService } from '../../../data/interactions.service';
import { CommunityService } from '../../../data/community.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-community-popup',
  templateUrl: './community-popup.component.html',
  styleUrls: ['./community-popup.component.scss'],
})
export class CommunityPopupComponent implements OnInit, AfterViewInit, OnDestroy {

  communitiesInfos?: CommunityInfos;
  isMember: boolean = false;
  selectedTag: Observable<TagLink | undefined > = this.mapService.selectedTagLink;
  bookmarked?: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private tiles!: TileData[];
  private selectedTile?: WorldPoint;


  private endFocusSub?: Subscription;
  private mapDataSub?: Subscription;
  private bookmarkSub?: Subscription;

  constructor(private mapService: MapService,
    private interactions: InteractionsService,
    private communityService: CommunityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.endFocusSub = this.mapService.endFocused!.subscribe((value) => {
      if (value) {
        this.goToContent()
      }
    });
    this.mapDataSub = this.mapService.mapData!.subscribe((md) => {
      if (md) {
        this.tiles = md.tiles.filter(tile => tile.communityId == this.communitiesInfos?.id);
      }
    });
    this.isMember = this.communityService.myCommunities.value?.includes(this.communitiesInfos?.id!) ?? false;
    this.bookmarkSub = this.communityService.myBookmarks.pipe(map(v => v.find(id => this.communitiesInfos?.id == id) != null)).subscribe(this.bookmarked)
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.endFocusSub!.unsubscribe();
    this.mapDataSub!.unsubscribe();
    this.bookmarkSub!.unsubscribe();
  }

  async seeCommunityPost(event: MouseEvent) {
    event.stopPropagation();
    let i = Math.floor(Math.random() * this.tiles.length);
    this.selectedTile = this.tiles[i].position;

    this.mapService.goToCoordinates([this.tiles[i].position[0], this.tiles[i].position[1]]);
  }

  goToContent() {
    if (!this.selectedTile) return;

    this.mapService.selectNewTile(this.selectedTile);
    this.interactions.sendSignal<boolean>(this.interactions.viewTileButtonClicked, true);
  }

  joinCommunity() {
    if (!this.communitiesInfos?.id) return

    this.communityService.joinCommunity(this.communitiesInfos.id);
  }

  leaveCommunity() {
    if (!this.communitiesInfos?.id) return

    this.communityService.leaveCommunity(this.communitiesInfos.id);
  }

  showRelatedCommunities(event: Event, tag: string) {
    event.stopPropagation();
    this.selectedTag?.pipe(
      first(),
      tap(selectedTagValue => {
        if (selectedTagValue && selectedTagValue.tag == tag) {
          this.mapService.clearTagLinks();
          this.mapService.selectedTagLink.next(undefined);
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { tag: null, origin: null },
            queryParamsHandling: 'merge'
          });
        } else {
          if (!this.communitiesInfos) return;
          this.mapService.selectedTagLink.next({ originCommunityId: this.communitiesInfos.id, tag });
          this.router.navigate([], { 
            relativeTo: this.activatedRoute,
            queryParams: { tag, origin: this.communitiesInfos.id },
            queryParamsHandling: 'merge'
          });
        }
      })
    ).subscribe();
  }

  updateBookmark(event: Event) {
    event.stopPropagation();

    if (!this.communitiesInfos?.id) return

    if (this.bookmarked) {
      if (this.bookmarked.value) {
        this.communityService.remBookmark(this.communitiesInfos.id);
      }
      else {
        this.communityService.addBookmark(this.communitiesInfos.id);
      }
    }
  }
}