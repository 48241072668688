export class StandardError
{
    constructor(msg: string)
    {
        this.errorMsg = msg;
    }

    errorMsg: string;
}


