import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CommunityService } from 'app/src/app/data/community.service';
import { MapService } from 'app/src/app/data/map.service';
import { Observable, map, tap } from 'rxjs';

@Component({
  selector: 'app-communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.scss'],
})
export class CommunitiesComponent implements OnInit {

  isMobile?: boolean
  modalBreakpoints = [0, 0.5, 1];

  constructor(
    private communityService: CommunityService,
    private platform: Platform) { }

  myCommunities$: Observable<string[]> = this.communityService.myCommunities$.pipe(
    map(communityIds => communityIds.map(communityId => this.communityService.getCommunityName(communityId) || ''))
  );

  ngOnInit() {
    this.isMobile = this.platform.is('mobile');
  }

}
