<div class="users-list">
  @for(user of (users | async); track $index)
  {
  <div class="user">
    <div class="user-infos">
      <app-avatar class="avatar" [image]="'https://ui-avatars.com/api/?name='+user.username.replace('','+')"
        [type]="'user'"></app-avatar>
      <div class="username">
        {{ user.username }}

        @if (isUserCreator(user.id)) {
        <img src="assets/icon/crown.svg" class="user-icon" alt="Creator" />
        } @else if (isUserAdmin(user.id)) {
        <ion-icon name="shield" class="user-icon" alt="Administrator"></ion-icon>
        }@else if(isUserModerator(user.id)) {
        <ion-icon name="shield-half" class="user-icon" alt="Moderator"></ion-icon>
        }
      </div>
    </div>

    @if (!isSelf(user.id)) {
    <ion-button (click)="displayPopover($event, $index)" [id]="'popover-button-'+$index" class="menu-button" fill="clear">
      <ion-icon name="ellipsis-vertical"></ion-icon>
    </ion-button>
    <ion-popover #popover [id]="'popover-'+$index" [dismissOnSelect]="true">
      <ng-template>
        <ion-content>
          <ion-list>
            <ion-item [button]="true" [detail]="false" (click)="seeUserProfile(user.id)">
              See profile
            </ion-item>
            @if (isCreator && isAdmin) {
            <ion-item [button]="true" [detail]="false"
              (click)="isUserAdmin(user.id) ? demoteAdmin(user.id) : promoteAdmin(user.id)">
              {{isUserAdmin(user.id) ? 'Demote administrator' : 'Promote administrator'}}
            </ion-item>
            }
            @if (isAdmin && !isUserCreator(user.id)) {
            <ion-item [button]="true" [detail]="false"
              (click)="isUserModerator(user.id) ? demoteModerator(user.id) : promoteModerator(user.id)">
              {{isUserModerator(user.id) ? 'Demote moderator' : 'Promote moderator'}}
            </ion-item>
            }
            @if ((isAdmin || isModerator) && !isUserAdmin(user.id) && !isUserModerator(user.id) && !isUserCreator(user.id)) {
            <ion-item [button]="true" [detail]="false" (click)="banUser(user.id)">
              Ban user
            </ion-item>
            }
          </ion-list>
        </ion-content>
      </ng-template>
    </ion-popover>
    }
  </div>
  }
</div>


@if ((isModerator || isAdmin) && (bannedUsers | async)?.length) {
<div class="banned-users-list">
  <h4>Banned users</h4>
  @for(user of (bannedUsers | async); track $index) {
  <div class="user">
    <div class="user-infos">
      <ion-avatar class="avatar">
        <img [src]="'https://ui-avatars.com/api/?name='+user.username.replace('','+')" />
      </ion-avatar>
      <div class="username">
        {{ user.username }}
      </div>
    </div>
    <ion-button [id]="'popover-button-'+user.id" class="menu-button" fill="clear">
      <ion-icon name="ellipsis-vertical"></ion-icon>
    </ion-button>
    <ion-popover [trigger]="'popover-button-'+user.id" [dismissOnSelect]="true">
      <ng-template>
        <ion-content>
          <ion-list>
            <ion-item [button]="true" [detail]="false" (click)="unbanUser(user.id)">
              Unban user
            </ion-item>
          </ion-list>
        </ion-content>
      </ng-template>
    </ion-popover>
  </div>
  }
</div>
}