import { Component, Input, OnInit } from '@angular/core';
import { InteractionsService } from 'app/src/app/data/interactions.service';
import { MapService } from 'app/src/app/data/map.service';

@Component({
  selector: 'app-go-button',
  templateUrl: './go-button.component.html',
  styleUrls: ['./go-button.component.scss'],
})
export class GoButtonComponent  implements OnInit {

  @Input() coordinates: String | undefined

  constructor(private mapService: MapService, 
              private interactionService: InteractionsService) { }

  ngOnInit() {}

  go()
  {
    let coords = this.coordinates?.split(',')
    this.mapService.goToCoordinates([+coords![0], +coords![1]])

    this.interactionService.sendSignal(this.interactionService.goButtonClicked, true)
  }
}
