<div id="main-container" [ngStyle]="{ 'border-color': poll?.color }"
  (click)="expandCollapse()">
  <div class="header">
    <p>{{ poll!.title }}</p>
    @if (voteDelayExpired){
    <ion-icon class="ended-icon" name="checkmark-circle-outline"></ion-icon>
    }
    @else {
    <div class="remaining">
      <ion-icon name="hourglass-outline"></ion-icon>
      <span>{{ remainingTime | duration }}</span>
    </div>
    }
  </div>
  @if (expanded)
  {
  @for(choice of sortedChoices; track $index)
  {
  <app-poll-choice #choice [pollId]="poll?.id" [pollChoice]="choice" [color]="poll?.color"
    [totalVotes]="poll?.nbVoters" [isWinning]="0 === $index && choice.score > sortedChoices![1].score"
    [displayResults]="(answered | async)! || voteDelayExpired"
    [selected]="selectedIndex === choice.index"></app-poll-choice>
  }
  @if (canModerate && poll) {
    <app-simple-button text="Delete poll" (click)="deletePoll($event)"></app-simple-button>
    }
  }


</div>