<div id="layout">
  <div id="header">
    @if (type == 'stars')
    {
      <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
        <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
          <stop offset="46%" stop-color="#F9DB78"/>
          <stop offset="100%" stop-color="#938147"/>
        </linearGradient>
        <path 
          fill="url(#Gradient2)"
          d="m7.625 6.4l2.8-3.625q.3-.4.713-.587T12 2t.863.188t.712.587l2.8 3.625l4.25 1.425q.65.2 1.025.738t.375 1.187q0 .3-.088.6t-.287.575l-2.75 3.9l.1 4.1q.025.875-.575 1.475t-1.4.6q-.05 0-.55-.075L12 19.675l-4.475 1.25q-.125.05-.275.063T6.975 21q-.8 0-1.4-.6T5 18.925l.1-4.125l-2.725-3.875q-.2-.275-.288-.575T2 9.75q0-.625.363-1.162t1.012-.763z"/>
      </svg>
    }
    @else if (type == 'donation')
    {
      <ion-icon id="donation" name="heart"></ion-icon>
    }
    @else
    {
      <svg width="2em" height="2em" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="42" height="42" rx="9" stroke="#DCB253" stroke-width="5"/>
      </svg>
    }
    <p>{{ name | itemName:type }}</p>
  </div>
  <form [action]="apiURL + '/payment/create-checkout-session'" method="POST">
    <input type="hidden" name="product" [value]="productId" />
    <ion-button [ngClass]="'buy ' + type" type="submit" id="checkout-button">
      <p>{{ price | price }}</p>
    </ion-button>
  </form>
</div>

