<ion-modal #modal [initialBreakpoint]="modalBreakpoints[1]" [breakpoints]="modalBreakpoints">
  <ng-template>
    <div id="modalContent">
      <ion-searchbar #searchBar animated="true" placeholder="Search on Tenor" (ionInput)="searchGIFS()" (ionChange)="searchGIFS()"></ion-searchbar>
      <div id="results">
        <div id="gifcontainer" [style]="'height:' + height + 'px'">
          @for (obj of currentGIFS; track $index)
          {
            @if (obj)
            {
              <ion-button (click)="gifClicked(obj.id)">
                <img [src]="obj.mediaFormats['tinygif'].url"/>
              </ion-button>
            }
          }
        </div>
      </div>
    </div>
  </ng-template>  
</ion-modal>
