import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'oBToReward'
})
export class OBToRewardPipe implements PipeTransform {

  transform(value: number | null, ...args: unknown[]): number {
    if (value)
    {
      return Math.round(0.01 * value)
    }

    return 0
  }

}
