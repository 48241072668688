import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SwipeDirection } from '@overlie/types';

@Component({
  selector: 'app-swipe-overlay',
  templateUrl: './swipe-overlay.component.html',
  styleUrls: ['./swipe-overlay.component.scss'],
})
export class SwipeOverlayComponent  implements OnInit {
  @Output() public buttonClicked = new EventEmitter<{ direction: SwipeDirection }>();
  @Input() public availableDirections: SwipeDirection[] = ['left', 'right', 'top', 'bottom'];
  @Input() public directionsWithNewContent: SwipeDirection[] = [];

  constructor() {
   }

  ngOnInit() {
  }

  clickOnSwipeButton(direction: SwipeDirection) {
    this.buttonClicked.emit({ direction });
  }

  getButtonClasses(direction: SwipeDirection) {
    return {
      'swipe-button': true,
      'interactable': true,
      'swipe-left': direction === 'left',
      'swipe-right': direction === 'right',
      'swipe-top': direction === 'top',
      'swipe-bottom': direction === 'bottom',
      'hidden': !this.availableDirections.includes(direction),
    };
  }

  
  getNewLabelClass(direction: SwipeDirection)
  {
    return {
      'new-label': true,
      'new-label-left': direction === 'left',
      'new-label-right': direction === 'right',
      'new-label-top': direction === 'top',
      'new-label-bottom': direction === 'bottom',
      'hidden': !this.directionsWithNewContent.includes(direction)
    }
  }
}
