<ion-fab-button [mode]="tileSelectionMode" id="overlie-add-button" class="interactable" (click)="putTile()">
    <ion-icon name="add"></ion-icon>
</ion-fab-button>
<ion-fab-button [mode]="tileSelectionMode" id="overlie-create-button" class="interactable" (click)="createCommunity()">
    <p>Create community</p>
</ion-fab-button>
<ion-fab-button [mode]="tileSelectionMode" id="overlie-view-button" class="interactable" (click)="viewTile()">
    <ion-icon name="view"></ion-icon>
</ion-fab-button>
<p>{{ timer$ | async | timer}}</p>
<p>Tile available: {{ tileAvailable$ | async }}</p>